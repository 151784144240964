import * as React from "react";
import PrognosisMenu from "./prognosis_menu";
import { ResultComponent, ResultBodyComponent, ResultHeaderComponent } from "./result_component";
import PrognosisMatrixComponent from "./prognosis_matrix";
import { connect } from "react-redux";
import HousingWizard from "./wizards/housing_wizard";
import { IRootState, Sections, ViewMode, setSelectedSection } from "../reducers/rootReducer";
import TopMenu from "./top_menu";
import KalpWizard from "./wizards/kalp_wizard";
import LoanWizard from "./wizards/loan_wizard";
import SavingsWizard from "./wizards/savings_wizard";
import { withTranslation, WithTranslation, useTranslation } from "react-i18next";
import Result from "../models/result";
import { AnimationSwitch, CSSAnimation } from "./wrappers/animations";

type OnToggleSectionType = (Sections, hasData: boolean, expandAfterAnimation?: boolean) => void;

export interface IResultProps extends WithTranslation {
    selectedSection: Sections;
    selectedViewMode: ViewMode;
    onToggleSection: OnToggleSectionType;
    result: Result;
}

class ResultsComponent extends React.Component<IResultProps> {
    constructor(props) {
        super(props);
    }

    render() {
        const { selectedViewMode, result } = this.props;
        const showInput = selectedViewMode === ViewMode.Input;

        return (
            <div id="econans-results">
                <TopMenu />
                <AnimationSwitch>
                    <CSSAnimation key={showInput} timeout={300} classNames="animate-fade-matrix">
                        {showInput ? (
                            <InputComponent onToggleSection={this.props.onToggleSection} result={result}></InputComponent>
                        ) : (
                            <PrognosisComponent onToggleSection={this.props.onToggleSection} result={result}></PrognosisComponent>
                        )}
                    </CSSAnimation>
                </AnimationSwitch>
            </div>
        );
    }
}

function mapStateToProps(state: IRootState) {
    const { selectedSection, selectedViewMode, result } = state;

    return {
        selectedSection,
        selectedViewMode,
        result,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onToggleSection: (section: Sections, hasData: boolean, expandAfterAnimation?: boolean) => {
            dispatch(setSelectedSection({ section, hasData }));

            if (hasData && expandAfterAnimation) {
                setTimeout(() => {
                    dispatch(setSelectedSection({ section, hasData }));
                }, 300);
            }
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResultsComponent));

function sectionAttributes(result: Result, section: Sections, onToggleSection: OnToggleSectionType) {
    return {
        section,
        disabled: section !== Sections.Housing && !result?.housing,
        hasData: !!(result && result[section]),
        onToggle: onToggleSection,
    };
}

interface SectionProps {
    onToggleSection?: OnToggleSectionType;
    result?: Result;
}

function PrognosisComponent(props: SectionProps) {
    const { result, onToggleSection } = props;
    const { t } = useTranslation();

    return (
        <div>
            <PrognosisMenu showPrognosis={true} />
            <div className="result-sections">
                <ResultComponent {...sectionAttributes(result, Sections.Housing, onToggleSection)}>
                    <ResultHeaderComponent
                        title={t("common:housing")}
                        section={Sections.Housing}
                        data={result?.housing?.summary}
                    ></ResultHeaderComponent>
                    <ResultBodyComponent>
                        <PrognosisMatrixComponent items={result?.housing} section={Sections.Housing}></PrognosisMatrixComponent>
                    </ResultBodyComponent>
                </ResultComponent>
                <ResultComponent {...sectionAttributes(result, Sections.Kalp, onToggleSection)}>
                    <ResultHeaderComponent title={t("common:kalp")} section={Sections.Kalp} data={result?.kalp?.summary}></ResultHeaderComponent>
                    <ResultBodyComponent>
                        <PrognosisMatrixComponent items={result?.kalp} section={Sections.Kalp}></PrognosisMatrixComponent>
                    </ResultBodyComponent>
                </ResultComponent>
                <ResultComponent {...sectionAttributes(result, Sections.Loans, onToggleSection)}>
                    <ResultHeaderComponent title={t("common:loan")} section={Sections.Loans} data={result?.loans?.summary}></ResultHeaderComponent>
                    <ResultBodyComponent>
                        <PrognosisMatrixComponent items={result?.loans} section={Sections.Loans}></PrognosisMatrixComponent>
                    </ResultBodyComponent>
                </ResultComponent>
                <ResultComponent {...sectionAttributes(result, Sections.Savings, onToggleSection)}>
                    <ResultHeaderComponent
                        title={t("common:savings")}
                        section={Sections.Savings}
                        data={result?.savings?.summary}
                    ></ResultHeaderComponent>
                    <ResultBodyComponent>
                        <PrognosisMatrixComponent items={result?.savings} section={Sections.Savings}></PrognosisMatrixComponent>
                    </ResultBodyComponent>
                </ResultComponent>
            </div>
        </div>
    );
}

function InputComponent(props: SectionProps) {
    const { result } = props;
    const { t } = useTranslation();

    return (
        <div>
            <PrognosisMenu showPrognosis={false} />
            <div className="input-sections">
                <ResultComponent {...sectionAttributes(result, Sections.Housing, props.onToggleSection)}>
                    <ResultHeaderComponent
                        title={t("common:housing")}
                        section={Sections.Housing}
                        data={result?.housing?.summary}
                    ></ResultHeaderComponent>
                    <ResultBodyComponent>
                        <HousingWizard />
                    </ResultBodyComponent>
                </ResultComponent>
                <ResultComponent {...sectionAttributes(result, Sections.Kalp, props.onToggleSection)}>
                    <ResultHeaderComponent title={t("common:kalp")} section={Sections.Kalp} data={result?.kalp?.summary}></ResultHeaderComponent>
                    <ResultBodyComponent>
                        <KalpWizard />
                    </ResultBodyComponent>
                </ResultComponent>
                <ResultComponent {...sectionAttributes(result, Sections.Loans, props.onToggleSection)}>
                    <ResultHeaderComponent title={t("common:loan")} section={Sections.Loans} data={result?.loans?.summary}></ResultHeaderComponent>
                    <ResultBodyComponent>
                        <LoanWizard />
                    </ResultBodyComponent>
                </ResultComponent>
                <ResultComponent {...sectionAttributes(result, Sections.Savings, props.onToggleSection)}>
                    <ResultHeaderComponent
                        title={t("common:savings")}
                        section={Sections.Savings}
                        data={result?.savings?.summary}
                    ></ResultHeaderComponent>
                    <ResultBodyComponent>
                        <SavingsWizard />
                    </ResultBodyComponent>
                </ResultComponent>
            </div>
        </div>
    );
}
