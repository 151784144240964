import { DEDUCTION_THRESHOLD } from "../defaults";
import { IInterestDiscountLevel } from "../modules/tips/interest_discount_new_loan";
import { calculateLTV } from "./mortgage";

export interface IInterestData {
    id: string;
    label: string;
    value: number;
    discounts: Array<IInterestDiscount>;
    period: string;
}

interface IInterestDiscount {
    maxLTV: number;
    discount: number;
}

interface IBestInterestData {
    id: string;
    label: string;
    value: number;
    discount: number;
    period: string;
}

export default class InterestCalculator {
    /**
     * Returns best interest discount at loan to value ratio for specific fixation period
     *
     * @param {IInterestData} rate Interest data for specific fixation period
     * @param {number} mortgage Total mortgage amount
     * @param {number} price House market value
     * @returns {number} Best interest discount or at least 0
     */
    static getInterestDiscount(rate: IInterestData, mortgage: number | void, price: number | void): number {
        if (mortgage == null || price == null) {
            return 0;
        }
        const ltv = calculateLTV(mortgage, price);
        const discount = rate.discounts.find((discount) => ltv <= discount.maxLTV);

        return discount?.discount ?? 0;
    }

    /**
     * Calculates best interest data at loan to value ratio for all fixation periods
     *
     * @param {Array<IInterestData>} rate Interest data for all fixation periods
     * @param {number} mortgage Total mortgage amount
     * @param {number} price House market value
     * @returns {Array<IBestInterestData>} Best interest data for all fixation periods
     */
    static getInterestRatesWithDiscount(
        interestRates: Array<IInterestData>,
        mortgage: number | void,
        price: number | void,
    ): Array<IBestInterestData> {
        const ratesWithDiscount = interestRates.map((r) => {
            const discount = this.getInterestDiscount(r, mortgage, price);

            const rate = {
                ...r,
                value: r.value - discount,
                discount,
            };
            return rate;
        });

        return ratesWithDiscount;
    }

    /**
     * Finds best interest at loan to value ratio
     *
     * @param {Array<IInterestData>} interestRates Interest data for all fixation periods
     * @param {number} mortgage Total mortgage amount
     * @param {number} price House market value
     * @returns {IBestInterestData} Best interest data at loan to value ratio
     */
    static getInterestRate(interestRates: Array<IInterestData>, mortgage: number | void, price: number | void): IBestInterestData {
        // Reverse to get interests in descending period
        const rates = this.getInterestRatesWithDiscount(interestRates, mortgage, price).reverse();

        return rates.reduce((p, c) => (p.value < c.value ? p : c));
    }

    /**
     * Converts best interest data at loan to value ratio
     *
     * @param {Array<IInterestData>} interestRates Interest data for all fixation periods
     * @param {number} ltv mortgage loan to value ratio
     * @returns {IInterestDiscountLevel} Converted interest data
     */
    static getBestInterestByLTV(interestRates: Array<IInterestData>, ltv: number): IInterestDiscountLevel {
        const interestRate = this.getInterestRate(interestRates, ltv, 1);

        return {
            rate: ltv,
            interest: interestRate.value,
            discount: interestRate.discount,
            contract: interestRate.id,
        };
    }
}

export function getDownpaymentRateAtInterestDeductionThreshold(downpayment: number, calculationInterestRate: number): number {
    const loan = DEDUCTION_THRESHOLD / calculationInterestRate;
    const housingValue = loan + downpayment;
    const downpaymentRate = downpayment / housingValue;

    return downpaymentRate;
}
