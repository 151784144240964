import { IUserDataInterface } from "../user_data_interface";
import { ITranslation } from "../utility/internationalization";
import { IMortgageConfiguration } from "./mortgage";
import IOffer, { IOfferConfig, OfferConfigs, Show } from "./offer";
import UserData, { HousingType } from "./user_data";
import { ITipsConfiguration } from "components/tips_component";
import { IInterestData } from "functions/interest";
import { IScenarioData } from "reducers/rootReducer";

export interface IHousingTypes {
    [HousingType.condominium]?: {
        maintenance?: number;
        fee?: number;
    };
    [HousingType.house]?: {
        maintenance?: number;
    };
    [HousingType.cottage]?: {
        maintenance?: number;
    };
}

interface IInterestRateItem {
    id: string;
    label: string;
    value: number;
}

export interface IStandardValues {
    singleAdultHouseholdExpense: number;
    twoAdultHouseholdExpense: number;
    childFullTimeExpense: number;
    childHalfTimeExpense: number;
}

export class ITrackingConfiguration {
    userActivityReportInterval?: number;
    userActivityIdleTimeout?: number;
}

export interface IOfferCallbacks {
    [key: string]: (userData: IUserDataInterface) => void;
}

export class IPrognosisParameters {
    investmentInterestRate?: number;
    savingsInterestRate?: number;
    minDownpaymentRate?: number;
    housingTypes?: IHousingTypes;
    mortgageInterestRateOffer?: (userData: IUserDataInterface) => number;
    calculationInterestRate?: number;
    interestRateList?: (userData: IUserDataInterface) => Array<IInterestRateItem>;
    offers?: Array<IOffer>;
    mortgage?: IMortgageConfiguration;
    onEvent?: (data) => void;
    translation?: ITranslation;
    debug?: boolean;
    defaultValues?: UserData;
    initialState?: UserData;
    language?: string;
    tracking?: ITrackingConfiguration;
    tipsModules?: Array<string | ITipsConfiguration>;
    scenarios?: Array<string>;
    minLTVForTips?: number;
    hideEffectiveInterest?: boolean;
    standardValues?: IStandardValues;
}

export interface IPrognosisParametersOverrides
    extends Pick<
        IPrognosisParameters,
        | "minDownpaymentRate"
        | "calculationInterestRate"
        | "investmentInterestRate"
        | "savingsInterestRate"
        | "hideEffectiveInterest"
        | "translation"
        | "onEvent"
        | "language"
        | "defaultValues"
        | "initialState"
        | "standardValues"
        | "debug"
    > {
    offers?: Array<IOffer> | IOfferCallbacks;
    interestRates?: Array<IInterestData>;
}

const getOfferConfig = <T extends OfferConfigs>(
    section: string,
    scenarioData: IScenarioData,
    userData: IUserDataInterface,
    show: Show<T>,
    override: (userData: IUserDataInterface) => void,
): IOfferConfig<T> => {
    const conf = show(section, scenarioData, userData);
    if (!conf.config) return;

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    (conf.config as any).onClick = override;

    return conf;
};

export const overrideOffersConfig = (defaultConfig: IPrognosisParameters, override: Array<IOffer> | IOfferCallbacks): IPrognosisParameters => {
    if (Array.isArray(override)) {
        defaultConfig.offers = override;
        return defaultConfig;
    }

    if (!defaultConfig || !override) {
        return defaultConfig;
    }

    if (typeof override === "object") {
        Object.keys(override).forEach((key) => {
            const originalConfig = (defaultConfig.offers || []).find((conf) => conf.module === key);
            if (!originalConfig) {
                return;
            }

            const originalShow = originalConfig.show;
            originalConfig.show = <T extends OfferConfigs>(section, scenarioData, userData): IOfferConfig<T> => {
                return getOfferConfig<T>(section, scenarioData, userData, originalShow as Show<T>, override[key]);
            };
        });
    }

    return defaultConfig;
};
