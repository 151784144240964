import taxTable from "../utility/tax_table";
import UserData, { Person } from "../models/user_data";
import { ESTIMATED_SALARY_INCREASE_RATE, RETIREMENT_AGE } from "../defaults";

export function getNetIncome(person: Person): number {
    const income = Math.ceil(person.income);
    const { value, retirement } = taxTable.find(({ from, to }) => income >= from && income <= to);
    let tax;

    if (hasReachedRetirementAge(person)) {
        tax = incomeTax(income, retirement);
    } else {
        tax = incomeTax(income, value);
    }

    return income - tax;
}

function incomeTax(income: number, value: number): number {
    let tax;
    if (isHighIncome(income)) {
        tax = Math.round(income * (value / 100));
    } else {
        tax = value;
    }

    return tax;
}

export function forecastPerson(person: Person, inYears: number): Person {
    const retirementFactor = 0.6;
    const { employmentRate, partTimeFrom, partTimePeriod } = person;
    let { age, income } = person;

    for (let i = 0; i < inYears; i++) {
        age = age + 1;
        if (age === RETIREMENT_AGE) {
            income = Math.round(income * retirementFactor);
        } else if (age < RETIREMENT_AGE) {
            income = Math.round(income * (1 + ESTIMATED_SALARY_INCREASE_RATE));
        }
    }

    if (employmentRate !== 1 && age < RETIREMENT_AGE && inYears >= partTimeFrom && inYears < partTimeFrom + partTimePeriod) {
        income = income * employmentRate;
    }

    return { ...person, age, income };
}

function isHighIncome(income) {
    return income > 80000;
}

export function hasReachedRetirementAge({ age }: Person): boolean {
    return age >= RETIREMENT_AGE;
}

interface IForecastedIncome {
    income: number;
    netIncome: number;
}

export function forecastHouseholdIncome(userData: UserData, years: number) {
    const build = (nrOfYears, fn: (userData: UserData, year: number) => IForecastedIncome) => {
        const result: Array<IForecastedIncome> = [];
        for (let year = 0; year <= nrOfYears; year++) {
            result.push(fn(userData, year));
        }
        return result;
    };

    const forecastedIncome = build(years, householdIncomeForecastForYear);
    const forecastedNetIncome = forecastedIncome.map((income) => income.netIncome);
    const [nowIncome] = forecastedNetIncome;

    return {
        now: nowIncome,
        future: forecastedNetIncome,
    };
}

export function householdIncomeForecastForYear(userData: UserData, year: number): IForecastedIncome {
    const adults = forecastAdults(userData, year);

    return adults.reduce(
        (acc, person) => {
            return {
                income: acc.income + person.income,
                netIncome: acc.netIncome + getNetIncome(person),
            };
        },
        { income: 0, netIncome: 0 },
    );
}

function forecastAdults(userData: UserData, year: number) {
    const adults = userData.scenarioDefinedAdults || userData.household?.adults || [];
    return adults.map((adult) => forecastPerson(adult, year));
}
