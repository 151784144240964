import i18next from "i18next";
import { createSelector } from "reselect";

import { minAmortizationRate } from "../functions/installment";
import { IHousingResult, DataPoint } from "../models/result";
import {
    getImpliedMortgageAmount,
    getMaintenance,
    getFee,
    getPropertyTax,
    getHousingPriceByCalculationType,
    getCurrentHouseholdIncome,
} from "../selectors/household";
import UserData, { Loan, HousingType } from "../models/user_data";
import Config from "../config";
import { constructUserDataInterface } from "../user_data_interface";
import { formatLocalAmount } from "../utility/number_formatter";
import { IInfoTooltipProps } from "../components/chart/chart";
import { MortgageCalculationType } from "../models/mortgage";
import ResultBuilder from "../utility/result_builder";
import { householdIncomeForecastForYear } from "./income";
import { IHousingLoanScenario, IRootState, IScenarioData } from "../reducers/rootReducer";
import { applyScenariosToUserData } from "./calculations";
import {
    BASE_DEDUCTION_RATE,
    DEDUCTION_THRESHOLD,
    EXTRA_DEDUCTION_RATE,
    FALLBACK_INTEREST_RATE,
    HIGH_LOAN_TO_VALUE_THRESHOLD,
    LOAN_TO_INCOME_THRESHOLD,
    LOW_LOAN_TO_VALUE_THRESHOLD,
    MONTHS_IN_YEAR,
} from "../defaults";

interface IMortgageResult {
    result: Array<{
        amount: number;
        amortization: number;
        interestExpense: number;
    }>;
    infoTooltips?: Array<IInfoTooltipProps>;
}

interface ILTVLimits {
    maxLTVRatio?: number;
    minLTVRatio?: number;
}

/* Approved intervall is greater than minLTV and less or equal than maxLTV  */
export const isLoanOutsideLTVLimits = (state: IRootState, limits: ILTVLimits): boolean => {
    const { maxLTVRatio, minLTVRatio } = limits;
    if (maxLTVRatio < minLTVRatio) {
        return true;
    }

    const userData = state.userData;
    const scenarioMortgageData = state.scenarioData?.mortgageData;
    const houseValue = scenarioMortgageData?.housingValue || getHousingPriceByCalculationType(userData);
    const calculatedMortgage = getMortgage(userData, scenarioMortgageData);
    const currentLTVRatio = calculatedMortgage / houseValue;
    const underMinLTV = Number.isFinite(minLTVRatio) ? currentLTVRatio <= minLTVRatio : false;
    const overMaxLTV = Number.isFinite(maxLTVRatio) ? currentLTVRatio > maxLTVRatio : false;

    return underMinLTV || overMaxLTV;
};

export const getMortgage = (userData: UserData, scenarioData?: IHousingLoanScenario): number => {
    const household = userData?.household;
    if (scenarioData) {
        return scenarioData.mortgage;
    }
    if (household?.calculationType === MortgageCalculationType.new) {
        return household.price - household.downpayment;
    } else if (household?.mortgages && household.mortgages.length > 0) {
        return household.mortgages.reduce((acc, m) => acc + m.amount, 0);
    }

    return undefined;
};

export const getMortgageExpense = (userData: UserData, interest: number, loanValue?: number): number => {
    const scenarioData = {
        mortgageData: {
            housingValue: getHousingPriceByCalculationType(userData),
            mortgage: loanValue || getMortgage(userData),
        },
        mortgageInterestRate: {
            interest,
        },
        adultsWorkPartTime: userData.scenarioDefinedAdults,
    };
    const newUserData = applyScenariosToUserData(userData, scenarioData);
    const { amortization, interestExpense } = forecastMortgages(newUserData, 0).result[0];
    return amortization + interestExpense;
};

interface DecreaseLoanParams {
    userData: UserData;
    interest: number;
    loan: number;
    reduce: number;
    minReduceValue: number;
    reduceDivisor: number;
    expenseToRecede: number;
}

export const getLoanWithPositiveKalp = (params: DecreaseLoanParams): number => {
    const { reduce, minReduceValue, reduceDivisor, expenseToRecede } = params;
    const newLoanValue = params.loan - reduce;
    const currentMortgageExpense = getMortgageExpense(params.userData, params.interest, newLoanValue);

    if (currentMortgageExpense >= expenseToRecede) {
        return getLoanWithPositiveKalp({
            ...params,
            loan: newLoanValue,
        });
    }
    if (reduce > minReduceValue) {
        return getLoanWithPositiveKalp({
            ...params,
            reduce: reduce / reduceDivisor,
        });
    }

    return newLoanValue;
};

export function getMaxLoanByIncomeRequirement(userData: UserData): number {
    const householdIncome = getCurrentHouseholdIncome(userData);

    return householdIncome * MONTHS_IN_YEAR * LOAN_TO_INCOME_THRESHOLD;
}

interface FlatAmortizationParams {
    price: number;
    loan: number;
    income: number;
    startMortgage: number;
    additionalMortgageAmount: number;
    scenarioDefinedAmortization?: number;
}

export function getAmortizationWithRequirements(params: FlatAmortizationParams) {
    const { loan, scenarioDefinedAmortization } = params;
    if (Number.isFinite(scenarioDefinedAmortization)) {
        return Math.min(scenarioDefinedAmortization, Math.ceil(loan / MONTHS_IN_YEAR));
    }

    const { price, income, startMortgage, additionalMortgageAmount } = params;
    const calculatedMinRate = minAmortizationRate({
        income,
        price,
        loan,
        additionalMortgageAmount,
    });
    const thresholds = getInstallmentThresholds();

    const startThreshold = startMortgage / price;
    thresholds.push(startThreshold);

    const currentRatio = loan / price;
    const closestThresholdAbove = Math.min(...thresholds.filter((threshold) => threshold >= currentRatio));
    const loanAmountAtThreshold = Math.ceil(price * closestThresholdAbove);
    const amortization = Math.round((loanAmountAtThreshold * calculatedMinRate) / MONTHS_IN_YEAR);

    return amortization;
}

function distributeAmortizationScenario(mortgages: Array<Loan>, amortizationValue: number) {
    if (!Number.isFinite(amortizationValue)) {
        return mortgages;
    }

    mortgages = calculateAmortizationRatio(mortgages);
    mortgages.map((mortgage) => {
        mortgage.amortization = amortizationValue * mortgage.amortizationRatio;
    });

    return mortgages;
}

export function getInstallmentThresholds(): Array<number> {
    return [HIGH_LOAN_TO_VALUE_THRESHOLD, LOW_LOAN_TO_VALUE_THRESHOLD];
}

const getInterestRateOffer = (userData: UserData) => {
    // TODO: need to verify that this is actually a good idea
    if (!userData) {
        return FALLBACK_INTEREST_RATE;
    }

    if (Number.isFinite(userData.scenarioDefinedInterest)) {
        return userData.scenarioDefinedInterest;
    }

    const offerFunc = Config.get("mortgageInterestRateOffer");
    const calculatedInterest = typeof offerFunc === "function" && offerFunc(constructUserDataInterface(userData));
    const interestRate = Number.isFinite(calculatedInterest) ? calculatedInterest : FALLBACK_INTEREST_RATE;
    return interestRate;
};

export const getInterestRate = createSelector([getInterestRateOffer], (interestRate) => interestRate);

export function forecastExistingMortgage(mortgage: Loan, inYears: number) {
    let { amount, amortization } = mortgage;
    const { interest } = mortgage;
    let interestExpense = monthlyInterestExpense({
        amortization: 0,
        amount,
        interest,
    });

    if (amount <= 0) {
        return { amount, amortization: 0, interestExpense: 0 };
    }

    for (let year = 1; year <= inYears; year++) {
        const totalInstallmentsForYear = amortization * MONTHS_IN_YEAR;

        amount = Math.max(amount - totalInstallmentsForYear, 0);

        if (totalInstallmentsForYear > amount) {
            amortization = Math.ceil(amount / MONTHS_IN_YEAR);
        }

        interestExpense = monthlyInterestExpense({
            amortization,
            amount,
            interest,
        });
    }

    return { amount, amortization, interestExpense };
}

export function getAmortizationRatio(amortization: number, mortgage: number): number {
    const yearlyAmortization = amortization * MONTHS_IN_YEAR;
    const amortizationRatio = mortgage > 0 ? (yearlyAmortization / mortgage) * 100 : 0;
    return Math.round((amortizationRatio + Number.EPSILON) * 100) / 100;
}

function calculateAmortizationRatio(mortgages: Array<Loan>, currentAmortization = 1): Array<Loan> {
    mortgages.forEach((mortgage) => {
        if (mortgage.amount <= 0) {
            mortgage.amortization = 0;
            mortgage.amortizationRatio = 0;
        }
    });

    const mortgageAmount = mortgages.reduce((acc, m) => acc + m.amount, 0);
    if (mortgageAmount <= 0) {
        return mortgages;
    }

    let totalAmortization = mortgages.reduce((acc, mortgage) => acc + mortgage.amortization, 0);
    if (totalAmortization === 0) {
        totalAmortization = currentAmortization;
        mortgages.find((mortgage) => mortgage.amount >= totalAmortization).amortization = totalAmortization;
    }
    mortgages.forEach((mortgage) => {
        mortgage.amortizationRatio = mortgage.amortization / totalAmortization;
    });

    return mortgages;
}

export function mortgagesAfterInstallment(mortgages: Array<Loan>, totalAmortization: number): Array<Loan> {
    let amortizationRest = 0;
    mortgages.map((mortgage) => {
        const mortgageAmortization = totalAmortization * mortgage.amortizationRatio;
        amortizationRest += mortgage.amount - mortgageAmortization < 0 ? Math.abs(mortgage.amount - mortgageAmortization) : 0;
        mortgage.amount = Math.max(mortgage.amount - mortgageAmortization, 0);
    });

    if (amortizationRest) {
        mortgages = calculateAmortizationRatio(mortgages, amortizationRest);
        mortgages = mortgagesAfterInstallment(mortgages, amortizationRest);
    }

    return mortgages;
}

function yearResultWithAmortizationRequirements(
    userData: UserData,
    mortgages: Array<Loan>,
    housingPrice: number,
    maxHouseholdIncome: number,
    startMortgageAmount: number,
    additionalMortgageAmount: number,
) {
    const totalAmount = mortgages.reduce((acc, mortgage) => acc + mortgage.amount, 0);

    const totalAmortization = getAmortizationWithRequirements({
        price: housingPrice,
        loan: totalAmount,
        income: maxHouseholdIncome,
        startMortgage: startMortgageAmount,
        additionalMortgageAmount,
    });
    const totalInterestExpenseBeforeDeduction = mortgages.reduce((acc, mortgage) => {
        const amortization = totalAmount < startMortgageAmount ? totalAmortization * mortgage.amortizationRatio : 0;
        const interest = Number.isFinite(userData.scenarioDefinedInterest) ? userData.scenarioDefinedInterest : mortgage.interest;
        return (
            acc +
            monthlyInterestExpense({
                amount: mortgage.amount,
                amortization,
                interest,
            })
        );
    }, 0);
    const totalInterestExpenseAfterDeduction = monthlyInterestExpenseAfterDeduction(totalInterestExpenseBeforeDeduction);

    return {
        amortization: totalAmortization,
        amount: totalAmount,
        interestExpense: Math.ceil(totalInterestExpenseAfterDeduction),
    };
}

function setInfoTooltip(year: number, rate: number, amortizationRate: number) {
    const changeDirection = rate > amortizationRate ? "up" : "down";
    return {
        year,
        text: i18next.t(`housing:amortization-${changeDirection}`, {
            rate: formatLocalAmount(rate * 100),
        }),
    };
}

export function forecastExistingLoansWithAmortizationRequirements(userData: UserData, years: number): IMortgageResult {
    // The reason to track the income history is because the amortization
    // requirements is partly based on income. If you reach an income level where
    // you no longer need to amortize extra, you shouldn't regress and need to
    // pay it again if you make less in the future (which we forecast that a
    // person near retirement age will).
    let householdIncome = householdIncomeForecastForYear(userData, 0);
    const incomeHistory = [householdIncome.income];
    const housingPrice = userData.household.estimatedValue;

    let mortgages: Array<Loan> = userData.household.mortgages.map((mortgage) => {
        return {
            ...mortgage,
            amortizationRatio: 0,
        };
    });
    mortgages = calculateAmortizationRatio(mortgages);

    const startMortgageAmount = getMortgage(userData);
    const initialAdditionalMortgageAmount = userData.kalp?.additionalHousing?.data?.mortage?.amount ?? 0;

    let currentResult = yearResultWithAmortizationRequirements(
        userData,
        mortgages,
        housingPrice,
        householdIncome.income,
        startMortgageAmount,
        initialAdditionalMortgageAmount,
    );
    const result = [currentResult];

    const infoTooltips: Array<IInfoTooltipProps> = [];
    let amortizationRate = minAmortizationRate({
        price: housingPrice,
        loan: currentResult.amount,
        income: householdIncome.income,
        additionalMortgageAmount: initialAdditionalMortgageAmount,
    });
    infoTooltips.push({
        year: 0,
        text: i18next.t("housing:initial-amortization", {
            rate: formatLocalAmount(amortizationRate * 100),
        }),
    });

    const additionalAmortization = userData.kalp?.additionalHousing?.data?.mortage?.amortization ?? 0;
    let additionalMortgageAmount;
    for (let year = 1; year <= years; year++) {
        mortgages = mortgagesAfterInstallment(mortgages, currentResult.amortization * MONTHS_IN_YEAR);

        householdIncome = householdIncomeForecastForYear(userData, year);
        incomeHistory.push(householdIncome.income);
        const maxHouseholdIncome = Math.max(...incomeHistory);
        additionalMortgageAmount = Math.max(initialAdditionalMortgageAmount - additionalAmortization * MONTHS_IN_YEAR * year, 0);

        currentResult = yearResultWithAmortizationRequirements(
            userData,
            mortgages,
            housingPrice,
            maxHouseholdIncome,
            startMortgageAmount,
            additionalMortgageAmount,
        );
        result.push(currentResult);

        const rate = minAmortizationRate({
            price: housingPrice,
            loan: currentResult.amount,
            income: maxHouseholdIncome,
            additionalMortgageAmount,
        });
        // Compare rounded strings for precison
        const minRateHasChanged = formatLocalAmount(rate, 3, 3) !== formatLocalAmount(amortizationRate, 3, 3);
        if (minRateHasChanged) {
            infoTooltips.push(setInfoTooltip(year, rate, amortizationRate));
        }
        amortizationRate = rate;
    }

    return { result, infoTooltips };
}

export function forecastTotalExistingMortgages(userData: UserData, years: number): IMortgageResult {
    const usingRequirements =
        (userData.household.amortizationRequirementsAsDefault && !userData.scenarioDefinedAmortization) ||
        userData.scenarioDefinedAmortization?.usingRequirements;
    if (usingRequirements) {
        return forecastExistingLoansWithAmortizationRequirements(userData, years);
    }

    const { scenarioDefinedInterest } = userData;
    const mortgages: Array<Loan> = userData.household.mortgages.map((mortgage) => ({
        ...mortgage,
        amortizationRatio: 0,
    }));

    const distributedMortgages = distributeAmortizationScenario(mortgages, userData.scenarioDefinedAmortization?.amortizationValue);
    if (Number.isFinite(scenarioDefinedInterest)) {
        distributedMortgages.map((mortgage) => {
            mortgage.interest = scenarioDefinedInterest;
        });
    }

    const result = [];

    const infoTooltips: Array<IInfoTooltipProps> = [];

    const mortgagesPaidOff = distributedMortgages.map((mortgage) => mortgage.amount <= 0);

    // TODO: for-loop is ineffective and should be refactored
    for (let year = 0; year <= years; ++year) {
        const forecastedMortgages = distributedMortgages.map((mortgage) => forecastExistingMortgage(mortgage, year));

        const totalMonthlyInterestExpense = forecastedMortgages.reduce((acc, mortgage) => acc + mortgage.interestExpense, 0);
        const monthlyInterestAfterDeduction = monthlyInterestExpenseAfterDeduction(totalMonthlyInterestExpense);

        const mortgagesAfterDeduction = forecastedMortgages.map((mortgage) => {
            const weight = totalMonthlyInterestExpense ? mortgage.interestExpense / totalMonthlyInterestExpense : 0;
            return {
                ...mortgage,
                interestExpenseAfterDeduction: monthlyInterestAfterDeduction * weight,
            };
        });

        mortgagesAfterDeduction.forEach((m, i) => {
            if (m.amount <= 0 && !mortgagesPaidOff[i]) {
                infoTooltips.push({
                    year: year,
                    text: i18next.t("housing:one-of-several-loans-paid", {
                        index: i + 1,
                    }),
                });

                mortgagesPaidOff[i] = true;
            }
        });

        result.push(
            mortgagesAfterDeduction.reduce(
                (acc, mortgage) => {
                    return {
                        amount: acc.amount + mortgage.amount,
                        amortization: acc.amortization + mortgage.amortization,
                        interestExpense: acc.interestExpense + mortgage.interestExpenseAfterDeduction,
                    };
                },
                { amount: 0, amortization: 0, interestExpense: 0 },
            ),
        );
        result[year].interestExpense = Math.ceil(result[year].interestExpense);
    }
    return { result, infoTooltips };
}

export function forecastNewMortgage(userData: UserData, nrOfYears: number): IMortgageResult {
    const interestRate = getInterestRate(userData);
    const startMortgageAmount = getImpliedMortgageAmount({
        price: userData.household.price,
        downpayment: userData.household.downpayment,
    });
    let mortgageAmount = startMortgageAmount;
    let householdIncome = householdIncomeForecastForYear(userData, 0);
    const housingPrice = userData.household.price;
    const initialAdditionalMortgageAmount = userData.kalp?.additionalHousing?.data?.mortage?.amount ?? 0;
    const scenarioDefinedAmortization = userData.scenarioDefinedAmortization?.amortizationValue;
    let amortization = getAmortizationWithRequirements({
        scenarioDefinedAmortization,
        price: housingPrice,
        loan: mortgageAmount,
        income: householdIncome.income,
        startMortgage: mortgageAmount,
        additionalMortgageAmount: initialAdditionalMortgageAmount,
    });
    let interestExpense = monthlyInterestExpenseAfterDeduction(
        monthlyInterestExpense({
            amount: mortgageAmount,
            amortization: 0,
            interest: interestRate,
        }),
    );

    // The reason to track the income history is because the "amorteringskrav"
    // is partly based on income. If you reach an income level where you no longer
    // need to amortize extra, you shouldn't regress and need to pay it again if
    // you make less in the future (which we forecast that a person near
    // retirement age will).
    const incomeHistory = [householdIncome.income];

    let prevAmortizationRate = minAmortizationRate({
        price: housingPrice,
        loan: mortgageAmount,
        income: householdIncome.income,
        additionalMortgageAmount: initialAdditionalMortgageAmount,
    });

    const result = [
        {
            amortization,
            interestExpense: Math.ceil(interestExpense),
            amount: mortgageAmount,
        },
    ];

    const infoTooltips: Array<IInfoTooltipProps> = [];

    const isUsingScenarioDefinedAmortization = Number.isFinite(scenarioDefinedAmortization);
    if (!isUsingScenarioDefinedAmortization) {
        infoTooltips.push({
            year: 0,
            text: i18next.t("housing:initial-amortization", {
                rate: formatLocalAmount(prevAmortizationRate * 100),
            }),
        });
    }

    let mortgagePaidOff = mortgageAmount <= 0;

    const additionalAmortization = userData.kalp?.additionalHousing?.data?.mortage?.amortization ?? 0;
    let additionalMortgageAmount;
    for (let year = 1; year <= nrOfYears; year++) {
        const totalInstallmentsForYear = amortization * MONTHS_IN_YEAR;
        mortgageAmount = Math.max(mortgageAmount - totalInstallmentsForYear, 0);
        householdIncome = householdIncomeForecastForYear(userData, year);
        incomeHistory.push(householdIncome.income);
        const maxHouseholdIncome = Math.max(...incomeHistory);
        additionalMortgageAmount = Math.max(initialAdditionalMortgageAmount - additionalAmortization * MONTHS_IN_YEAR * year, 0);

        amortization = getAmortizationWithRequirements({
            scenarioDefinedAmortization: scenarioDefinedAmortization,
            price: housingPrice,
            loan: mortgageAmount,
            income: maxHouseholdIncome,
            startMortgage: startMortgageAmount,
            additionalMortgageAmount,
        });
        interestExpense = monthlyInterestExpenseAfterDeduction(
            monthlyInterestExpense({
                amount: mortgageAmount,
                amortization,
                interest: interestRate,
            }),
        );

        result.push({
            amortization,
            interestExpense: Math.ceil(interestExpense),
            amount: mortgageAmount,
        });

        const rate = minAmortizationRate({
            price: housingPrice,
            loan: mortgageAmount,
            income: maxHouseholdIncome,
            additionalMortgageAmount,
        });

        // Compare rounded strings for precison
        const minRateHasChanged = formatLocalAmount(rate, 3, 3) !== formatLocalAmount(prevAmortizationRate, 3, 3);
        if (!isUsingScenarioDefinedAmortization && minRateHasChanged) {
            const changeDirection = rate > prevAmortizationRate ? "up" : "down";
            infoTooltips.push({
                year: year,
                text: i18next.t(`housing:amortization-${changeDirection}`, {
                    rate: formatLocalAmount(rate * 100),
                }),
            });
        }

        if (mortgageAmount <= 0 && mortgagePaidOff === false) {
            infoTooltips.push({
                year,
                text: i18next.t("housing:one-of-one-loan-paid"),
            });
            mortgagePaidOff = true;
        }

        prevAmortizationRate = rate;
    }

    return { result, infoTooltips };
}

export function forecastMortgages(userData: UserData, years: number): IMortgageResult {
    if (!userData.household) {
        return {
            result: Array.from(Array(years + 1)).map(() => {
                return {
                    amount: 0,
                    amortization: 0,
                    interestExpense: 0,
                };
            }),
        };
    }

    if (userData.household?.calculationType === MortgageCalculationType.new) {
        return forecastNewMortgage(userData, years);
    } else {
        return forecastTotalExistingMortgages(userData, years);
    }
}

export function getTotalYearlyInterestExpenseForExistingMortgage(userData: UserData) {
    if (
        userData?.household?.calculationType === MortgageCalculationType.move &&
        userData?.household?.mortgages &&
        userData?.household?.mortgages.length > 0
    ) {
        const mortgages: Array<Loan> = userData.household.mortgages.map((mortgage) => ({
            ...mortgage,
            amortizationRatio: 0,
        }));
        const scenarioDefinedValue = userData?.scenarioDefinedAmortization?.amortizationValue;

        const interestBeforeDeduction = distributeAmortizationScenario(mortgages, scenarioDefinedValue)
            .map((mortgage) => {
                const interest = Number.isFinite(userData.scenarioDefinedInterest) ? userData.scenarioDefinedInterest : mortgage.interest;
                return monthlyInterestExpense({
                    amortization: mortgage.amortization,
                    amount: mortgage.amount,
                    interest,
                });
            })
            .reduce((total, amount) => {
                return total + amount;
            }, 0);

        return monthlyInterestExpenseAfterDeduction(interestBeforeDeduction) * MONTHS_IN_YEAR;
    }
    return 0;
}

export function forecastHousingExpenses(
    userData: UserData,
    years: number,
    scenarioData?: IScenarioData,
): { result: IHousingResult; infoTooltips?: Array<IInfoTooltipProps> } {
    if (!userData.household) {
        return undefined;
    }

    const forecastedMaintenance = forecastMaintenance(userData, years);
    const forecastedMortgage = forecastMortgages(userData, years);

    const maintenanceDataPoints = buildDataPoints(forecastedMaintenance, (maintenance) => maintenance);
    const amortizationDataPoints = buildDataPoints(forecastedMortgage.result, ({ amortization }) => amortization);
    const interestExpenseDataPoints = buildDataPoints(forecastedMortgage.result, ({ interestExpense }) => interestExpense);

    const resultBuilder = new ResultBuilder(years);
    const actualMortgage = getMortgage(userData);
    const scenarioMortgage = scenarioData?.mortgageData?.mortgage;
    if (actualMortgage > 0 || scenarioMortgage > 0) {
        resultBuilder.set("amortization", amortizationDataPoints);
        resultBuilder.set("interest", interestExpenseDataPoints);
    }
    resultBuilder.set("maintenance", maintenanceDataPoints);

    if (userData.household.housingType === HousingType.condominium) {
        const forecastedFee = forecastFee(userData, years);
        const feeDataPoints = buildDataPoints(forecastedFee, (fee) => fee);
        resultBuilder.set("fee", feeDataPoints);
    }
    if (userData.household.housingType === HousingType.house || userData.household.housingType === HousingType.cottage) {
        const forecastedPropertyTax = forecastPropertyTax(userData, years);
        const propertyTaxDataPoints = buildDataPoints(forecastedPropertyTax, (fee) => fee);
        resultBuilder.set("propertyTax", propertyTaxDataPoints);
    }

    return {
        result: resultBuilder.getResult(),
        infoTooltips: forecastedMortgage.infoTooltips,
    };
}

function buildDataPoints<T>(array: Array<T>, selector: (arg: T) => number): DataPoint {
    const arrayAfterSelection = array.map(selector);

    return {
        now: arrayAfterSelection[0],
        future: arrayAfterSelection,
    };
}

export function monthlyInterestExpense({
    amortization,
    amount,
    interest = 0.02,
}: {
    amortization: number;
    amount: number;
    interest: number;
}): number {
    const yearlyInterestExpense = interest * (amount - 5.5 * amortization);

    return yearlyInterestExpense / 12;
}

export function forecastMaintenance(userData: UserData, years: number) {
    let maintenance = 0;

    if (Number.isFinite(userData.scenarioDefinedMaintenance)) {
        maintenance = userData.scenarioDefinedMaintenance;
    } else {
        maintenance = getMaintenance(userData);
    }

    return Array.from(Array(years + 1)).map(() => maintenance);
}

export function forecastFee(userData: UserData, years: number) {
    const fee = getFee(userData);

    return Array.from(Array(years + 1)).map(() => fee);
}

export function forecastPropertyTax(userData: UserData, years: number) {
    const propertyTax = getPropertyTax(userData);
    const monthlyPropertyTax = Math.round(propertyTax / 12);

    return Array.from(Array(years + 1)).map(() => monthlyPropertyTax);
}

export function monthlyInterestExpenseAfterDeduction(monthlyBaseInterest: number): number {
    const yearlyBaseInterest = monthlyBaseInterest * MONTHS_IN_YEAR;
    const interestDeduction = deduction(yearlyBaseInterest);
    const yearlyInterest = yearlyBaseInterest - interestDeduction;
    const monthlyInterest = yearlyInterest / MONTHS_IN_YEAR;

    return monthlyInterest;
}

function deduction(baseInterestCost: number): number {
    if (baseInterestCost <= DEDUCTION_THRESHOLD) {
        return baseInterestCost * BASE_DEDUCTION_RATE;
    } else {
        const baseInterestDeduction = DEDUCTION_THRESHOLD * BASE_DEDUCTION_RATE;
        const extraInterestDeduction = (baseInterestCost - DEDUCTION_THRESHOLD) * EXTRA_DEDUCTION_RATE;

        return baseInterestDeduction + extraInterestDeduction;
    }
}

export function getMonthlyInterestExpenseAfterDeduction(mortgageAmount, amortization, interestRate) {
    return monthlyInterestExpenseAfterDeduction(
        monthlyInterestExpense({
            amount: mortgageAmount,
            amortization,
            interest: interestRate,
        }),
    );
}
