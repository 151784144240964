import { IRootState, ensure } from "./rootReducer";

export function setStudentLoansFn(state: IRootState, action) {
    const newState = ensure("loans", state);
    newState.userEditingData.loans.studentLoans = Array.from({ length: action.payload }, (value, index) => {
        return newState.userEditingData.loans.studentLoans && index < newState.userEditingData.loans.studentLoans.length
            ? Object.assign({}, newState.userEditingData.loans.studentLoans[index])
            : {
                  amount: 100000,
                  amortization: 900,
                  interest: 0.005,
              };
    });
    return newState;
}

export function setPrivateLoansFn(state: IRootState, action) {
    const newState = ensure("loans", state);
    newState.userEditingData.loans.privateLoans = Array.from({ length: action.payload }, (value, index) => {
        return newState.userEditingData.loans.privateLoans && index < newState.userEditingData.loans.privateLoans.length
            ? Object.assign({}, newState.userEditingData.loans.privateLoans[index])
            : {
                  amount: 100000,
                  amortization: 1000,
                  interest: 0.1,
              };
    });
    return newState;
}

export function setCarLoansFn(state: IRootState, action) {
    const newState = ensure("loans", state);
    newState.userEditingData.loans.carLoans = Array.from({ length: action.payload }, (value, index) => {
        return newState.userEditingData.loans.carLoans && index < newState.userEditingData.loans.carLoans.length
            ? Object.assign({}, newState.userEditingData.loans.carLoans[index])
            : {
                  amount: 100000,
                  amortization: 1000,
                  interest: 0.1,
              };
    });
    return newState;
}

export function setLoanAmountFn(loanType: string) {
    return (state, action) => {
        const newState = ensure("loans", state);
        newState.userEditingData.loans[loanType][action.payload.index].amount = action.payload.value;
        return newState;
    };
}

export function setLoanAmortizationFn(loanType: string) {
    return (state, action) => {
        const newState = ensure("loans", state);
        newState.userEditingData.loans[loanType][action.payload.index].amortization = action.payload.value;
        return newState;
    };
}

export function setLoanInterestFn(loanType: string) {
    return (state, action) => {
        const newState = ensure("loans", state);
        newState.userEditingData.loans[loanType][action.payload.index].interest = action.payload.value;
        return newState;
    };
}
