import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { IRootState, setAdditionalHousingMortgageAmortization, setAdditionalHousingMortgageAmount } from "../../../reducers/rootReducer";
import NumberComponent from "../../form_controls/number_component";
import { useTranslation } from "react-i18next";
import DisclaimerComponent from "../../typography/disclaimer_component";
import OverlineComponent from "../../typography/overline_component";
import { DEFAULT_ADDITIONAL_HOUSING_MORTGAGE_MAX } from "../../../defaults";

export default function KalpAdditionalHousingFeeStep(props) {
    const { amount, amortization } = useSelector((state: IRootState) => {
        return {
            amount: state.userEditingData.kalp.additionalHousing.data.mortage.amount,
            amortization: state.userEditingData.kalp.additionalHousing.data.mortage.amortization,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    // function onChangeAge(value: number) {
    //     props.onValidate(value < 20);
    //     dispatch(setChildAge({ index: props.index, value }));
    // }

    // function onChangeHouseholdRate(value: ChildHouseholdRate) {
    //     props.onValidate(true);
    //     dispatch(setChildHouseholdRate({ index: props.index, value }));
    // }
    function onChangeAmount(value: number) {
        props.onValidate(value >= 0);
        dispatch(setAdditionalHousingMortgageAmount(value));
    }

    function onChangeAmortization(value: number) {
        props.onValidate(true);
        dispatch(setAdditionalHousingMortgageAmortization(value));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("kalp:additional-housing")}</OverlineComponent>
            <DisclaimerComponent>{t("kalp:part-of-additional-housing")}</DisclaimerComponent>
            <NumberComponent
                name={"additional_housing_mortgage_amount"}
                unit={t("common:sek")}
                onChange={onChangeAmount}
                min={0}
                step={50000}
                defaultMax={DEFAULT_ADDITIONAL_HOUSING_MORTGAGE_MAX}
                label={t("kalp:input-mortgage-amount")}
                value={amount}
            ></NumberComponent>
            <NumberComponent
                name={"additional_housing_mortgage_amortization"}
                unit={t("common:sek")}
                onChange={onChangeAmortization}
                min={0}
                step={100}
                defaultMax={20000}
                label={t("kalp:input-amortization")}
                value={amortization}
            ></NumberComponent>
            <DisclaimerComponent>{t("kalp:input-interest-rate-disclaimer")}</DisclaimerComponent>
        </IndataStepComponent>
    );
}
