import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { ScenarioTemplate, IncompleteStateScenario, IScenarioProps } from "../scenarios";
import { IRootState, resetRenovationScenario, setRenovationScenario } from "../../reducers/rootReducer";
import NumberComponent from "../form_controls/number_component";
import SelectComponent from "../form_controls/select_component";
import { ScenarioEvent } from "../../utility/analytics";
import { formatLocalAmount } from "../../utility/number_formatter";

export const isRenovationScenarioActive = (cost?: number, year?: number) => {
    return cost > 0 && year > 0;
};

export const renovationMonthlyCost = (renovationCost: number, year: number) => {
    return Number.isFinite(renovationCost) && Number.isFinite(year) ? renovationCost / ((year - new Date().getFullYear()) * 12) : undefined;
};

export const RenovationScenario = (props: IScenarioProps) => {
    const { onBack, onEngagement } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { scenarioTarget, scenarioCost, scenarioYear } = useSelector((state: IRootState) => {
        return {
            scenarioTarget: state.scenarioData?.renovationTarget,
            scenarioCost: state.scenarioData?.renovationCost,
            scenarioYear: state.scenarioData?.renovationYear,
        };
    });

    const years = Array.from({ length: 10 }, (v, k) => {
        const inYears = k + 1;
        const year = (inYears + new Date().getFullYear()).toString();
        return {
            id: year,
            value: year,
            label: t("scenario:renovation-year-option", { years: inYears }),
        };
    });

    const existingSavings = useSelector((state: IRootState) => !!state.userData?.savings);

    const scenarioActivated = isRenovationScenarioActive(scenarioCost, scenarioYear);

    const costPerMonth = renovationMonthlyCost(scenarioCost, scenarioYear);

    const selectedCost = scenarioCost > 0 ? scenarioCost : 0;

    const defaultYear = new Date().getFullYear() + 5;
    useEffect(() => {
        if (!(scenarioYear > 0)) {
            dispatch(
                setRenovationScenario({
                    target: scenarioTarget,
                    cost: scenarioCost,
                    year: defaultYear,
                }),
            );
        }
    }, [scenarioYear]);

    if (!existingSavings) {
        return (
            <IncompleteStateScenario
                description={t("scenario:scenario-no-savings")}
                name={t("scenario:renovation-header")}
                onBack={onBack}
                category={t("dynamic:savings")}
            />
        );
    }

    const resetScenario = () => {
        dispatch(resetRenovationScenario());
    };

    const setScenarioTarget = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            setRenovationScenario({
                target: event.target.value,
                cost: scenarioCost,
                year: scenarioYear,
            }),
        );
    };
    const setScenarioCost = (value) => {
        onEngagement(ScenarioEvent.RENOVATION);
        dispatch(
            setRenovationScenario({
                target: scenarioTarget,
                cost: value,
                year: scenarioYear,
            }),
        );
    };
    const setScenarioYear = (value) => {
        onEngagement(ScenarioEvent.RENOVATION);
        const parsedYear = Number.parseInt(value.value);
        dispatch(
            setRenovationScenario({
                target: scenarioTarget,
                cost: scenarioCost,
                year: Number.isFinite(parsedYear) ? parsedYear : undefined,
            }),
        );
    };

    return (
        <ScenarioTemplate
            name={t("scenario:renovation-header")}
            onBack={onBack}
            onReset={resetScenario}
            scenarioActivated={scenarioActivated}
            category={t("dynamic:savings")}
        >
            <div className="input-container">
                <label>{t("scenario:renovation-target-label")}</label>
                <input
                    type="text"
                    placeholder={t("scenario:renovation-placeholder")}
                    onFocus={(event) => setTimeout(() => event.target.select(), 50)}
                    onChange={setScenarioTarget}
                    name="renovation_scenario_target"
                    value={scenarioTarget || ""}
                />
            </div>
            <NumberComponent
                name="renovation_scenario_cost"
                unit={t("common:sek")}
                onChange={setScenarioCost}
                min={0}
                step={1000}
                defaultMax={300000}
                label={t("scenario:renovation-cost-label")}
                value={selectedCost}
            />
            <SelectComponent
                id="renovation_scenario_year"
                onChange={setScenarioYear}
                items={years}
                value={scenarioYear?.toString() || defaultYear.toString()}
                label={t("scenario:renovation-year-label")}
            ></SelectComponent>
            {costPerMonth ? (
                <div>
                    <h4>{t("scenario:renovation-disclaimer")}</h4>
                    <p className="sum">{`${formatLocalAmount(Math.ceil(costPerMonth))} ${t("common:sek-month")}`}</p>
                    <h4>{t("scenario:renovation-disclaimer-secondary")}</h4>
                </div>
            ) : null}
        </ScenarioTemplate>
    );
};
