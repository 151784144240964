import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { applyBankKalpScenariosToUserData, convertToPercent } from "../../functions/calculations";
import { getMortgage, getMortgageExpense, getInstallmentThresholds, isLoanOutsideLTVLimits } from "../../functions/household";
import { minAmortizationRate } from "../../functions/installment";
import { isBankKalpNegativeOrZero } from "../../functions/kalp";
import { MortgageCalculationType } from "../../models/mortgage";
import { IRootState, setMortgageScenario } from "../../reducers/rootReducer";
import { getCurrentHouseholdIncome, getHousingPriceByCalculationType } from "../../selectors/household";
import { ITipsModuleProps, tipsModule } from "../../utility/module_store";
import { formatLocalAmount } from "../../utility/number_formatter";
import { processMarkdown } from "../../utility/markdown_processor";
import DisclaimerComponent from "../../components/typography/disclaimer_component";
import UserData from "../../models/user_data";
import { AccessibleButton } from "../../components/accessibility/accessible_components";
import { HIGH_LOAN_TO_VALUE_THRESHOLD, LOAN_TO_INCOME_THRESHOLD, LOW_LOAN_TO_VALUE_THRESHOLD, MONTHS_IN_YEAR } from "../../defaults";

const LOWEST_CHANGE_FACTOR_LOAN = 0.83;

export function HighAmortizationTipsComponent(props: ITipsModuleProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { scenarioData, userData } = props.rootState;
    const appliedUserData = applyBankKalpScenariosToUserData(userData, scenarioData, null);
    const userDefinedLoan = getMortgage(appliedUserData) || 0;
    const housingValue = getHousingPriceByCalculationType(appliedUserData);

    const currentLTV = userDefinedLoan / housingValue;
    const householdIncome = getCurrentHouseholdIncome(appliedUserData);
    const thresholdByIncome = (householdIncome * MONTHS_IN_YEAR * LOAN_TO_INCOME_THRESHOLD) / housingValue;
    const thresholds = [HIGH_LOAN_TO_VALUE_THRESHOLD, LOW_LOAN_TO_VALUE_THRESHOLD, thresholdByIncome];
    const closestThresholdBelow = Math.max(...thresholds.filter((threshold) => threshold < currentLTV));
    const loanAtClosestThreshold = Math.floor((housingValue * (closestThresholdBelow + Number.EPSILON)) / 1000) * 1000;
    const downpaymentDelta = userDefinedLoan - loanAtClosestThreshold;
    const newDownpayment = housingValue - loanAtClosestThreshold;

    const currentAmortization = getMortgageExpense(appliedUserData, 0);
    const newAmortization = getMortgageExpense(appliedUserData, 0, loanAtClosestThreshold);

    const additionalMortgageAmount = userData.kalp?.additionalHousing?.data?.mortage?.amount ?? 0;
    const minAmortizationInput = {
        price: housingValue,
        loan: userDefinedLoan,
        income: householdIncome,
        additionalMortgageAmount,
    };
    const currentAmortizationRate = convertToPercent(minAmortizationRate(minAmortizationInput));
    minAmortizationInput.loan = loanAtClosestThreshold;
    const newAmortizationRate = convertToPercent(minAmortizationRate(minAmortizationInput));

    const discountedInterest = props.configuration?.discounts
        .sort((first, second) => first.rate - second.rate)
        .find((discount) => discount.rate >= closestThresholdBelow);
    const mortgageInterestRate = Number.isFinite(discountedInterest?.interest) && { interest: discountedInterest.interest };

    const currentMortgageExpense = getMortgageExpense(appliedUserData, scenarioData?.mortgageInterestRate?.interest);
    const newMortgageExpense = getMortgageExpense(appliedUserData, discountedInterest?.interest, loanAtClosestThreshold);
    const monthlySavings = currentMortgageExpense - newMortgageExpense;

    const tipsText = t("tips:increase-downpayment-to-decrease-amortization", {
        downpaymentDelta: formatLocalAmount(downpaymentDelta),
        amortizationBefore: formatLocalAmount(currentAmortization),
        rateBefore: currentAmortizationRate,
        amortizationAfter: formatLocalAmount(newAmortization),
        rateAfter: newAmortizationRate,
    });
    const tipsDisclaimer = t("tips:increase-downpayment-to-decrease-amortization-disclaimer", {
        newDownpayment: formatLocalAmount(newDownpayment),
        newLTV: Math.round(convertToPercent(closestThresholdBelow)),
        rateBefore: currentAmortizationRate,
        rateAfter: newAmortizationRate,
        monthlySavings: formatLocalAmount(monthlySavings),
    });

    const setScenario = () => {
        props.onActivated();
        dispatch(
            setMortgageScenario({
                value: housingValue,
                mortgage: loanAtClosestThreshold,
                mortgageInterestRate,
            }),
        );
    };

    return (
        <div>
            <h4 dangerouslySetInnerHTML={{ __html: processMarkdown(tipsText) }}></h4>
            <AccessibleButton onClick={setScenario}>{t("tips:set-scenario")}</AccessibleButton>
            <DisclaimerComponent>
                <span
                    dangerouslySetInnerHTML={{
                        __html: processMarkdown(tipsDisclaimer),
                    }}
                ></span>
            </DisclaimerComponent>
            <DisclaimerComponent>{t("tips:no-offer")}</DisclaimerComponent>
        </div>
    );
}

function getMinLTVForTips(housingValue: number, householdIncome: number, minLTVForTips: number): number {
    const LTVByIncome = (householdIncome * MONTHS_IN_YEAR * LOAN_TO_INCOME_THRESHOLD) / housingValue;
    const showTipsForLowIncomeLTV = LTVByIncome > minLTVForTips && LTVByIncome < LOW_LOAN_TO_VALUE_THRESHOLD;

    return showTipsForLowIncomeLTV ? LTVByIncome : LOW_LOAN_TO_VALUE_THRESHOLD;
}

function isDownPaymentAppropriate(userData: UserData, housingValue: number, householdIncome: number) {
    const currentMortgage = getMortgage(userData) || 0;
    const currentLTV = currentMortgage / housingValue;
    const incomeLTV = householdIncome / housingValue;
    const thresholds = getInstallmentThresholds();
    thresholds.push(incomeLTV);
    const closestThresholdBelow = Math.max(...thresholds.filter((threshold) => threshold < currentLTV));

    const minAppropriateMortgage = currentMortgage * LOWEST_CHANGE_FACTOR_LOAN;
    const minAppropriateLTV = minAppropriateMortgage / housingValue;

    return minAppropriateLTV <= closestThresholdBelow;
}

function isTipsActive(state: IRootState) {
    const { userData, scenarioData } = state;
    if (!userData?.household) {
        return false;
    }

    if (isBankKalpNegativeOrZero(state)) {
        return false;
    }

    const appliedUserData = applyBankKalpScenariosToUserData(userData, scenarioData, 0);
    const housingValue = getHousingPriceByCalculationType(userData);
    if (housingValue <= 0) {
        return false;
    }

    const householdIncome = getCurrentHouseholdIncome(userData);

    const minLTVRatio = getMinLTVForTips(housingValue, householdIncome, state.minLTVForTips);
    if (isLoanOutsideLTVLimits(state, { minLTVRatio })) {
        return false;
    }

    return isDownPaymentAppropriate(appliedUserData, housingValue, householdIncome);
}

export default tipsModule(
    "high-amortization-new-loan",
    (state: IRootState) => !!state.userData && state.userData.household.calculationType === MortgageCalculationType.new && isTipsActive(state),
)(HighAmortizationTipsComponent);
