import { DEFAULT_CONDOMINIUM_FEE, DEFAULT_MAINTENANCE } from "../defaults";
import Config from "../config";
import { IHousingTypes } from "../models/config";
import { HousingType } from "../models/user_data";

//TODO: Refactor in accordance with how we handle calculation types.
export function getHousingTypes(): Array<HousingType> {
    const types = [];
    if (shouldRenderHouse()) {
        types.push(HousingType.house);
    }
    if (shouldRenderCondominium()) {
        types.push(HousingType.condominium);
    }
    if (shouldRenderCottage()) {
        types.push(HousingType.cottage);
    }
    return types;
}

export const shouldRenderHouse = () => {
    return shouldRenderHousingType(HousingType.house);
};

export const shouldRenderCondominium = () => {
    return shouldRenderHousingType(HousingType.condominium);
};

export const shouldRenderCottage = () => {
    return shouldRenderHousingType(HousingType.cottage);
};

const shouldRenderHousingType = (housingType: HousingType) => {
    const config = getHousingTypeConfig();
    return housingType in config ? !!config[housingType] : true;
};

export const getMaintenance = (housingType: HousingType) => {
    const config = getHousingTypeConfig();
    const configForHousingType = config[housingType] || {};

    return typeof configForHousingType.maintenance === "number" ? configForHousingType.maintenance : DEFAULT_MAINTENANCE[housingType];
};

export const getFee = (housingType: HousingType) => {
    const config = getHousingTypeConfig();

    if (housingType === HousingType.condominium) {
        const configForHousingType = config[housingType] || {};
        return typeof configForHousingType.fee === "number" ? configForHousingType.fee : DEFAULT_CONDOMINIUM_FEE;
    } else {
        return undefined;
    }
};

export const getPropertyTax = (housingType: HousingType, housingPrice: number) => {
    if (housingType === HousingType.house || housingType === HousingType.cottage) {
        return Math.min(Math.round(housingPrice * 0.0075), 8349);
    } else {
        return undefined;
    }
};

const getHousingTypeConfig = () => {
    const config: IHousingTypes = Config.get("housingTypes") || {};
    return config;
};
