import { ILoansResult, DataPoint } from "../models/result";
import UserData, { Loan } from "../models/user_data";
import { accumulate } from "./calculations";
import { forecastMortgages } from "./household";
import { getCurrentHouseholdIncome, getTotalMortgageAmount } from "../selectors/household";
import { sumDatapoints, sumArrays } from "../utility/datapoints";
import ResultBuilder from "../utility/result_builder";
import { Maybe } from "../utility/types";

interface ILoanForecast {
    amount: number;
    interest: number;
    installment: number;
}

function calculateYearlyInterest(initialAmount: number, monthlyInstallment: number, normalizedInterest: number): number {
    return Math.round((initialAmount - 5.5 * monthlyInstallment) * normalizedInterest);
}

export function forecastLoan(initialAmount: number, monthlyInstallment: number, interest: number, years: number): Array<ILoanForecast> {
    monthlyInstallment = Math.min(initialAmount / 12, monthlyInstallment);
    years = years || 1;

    const firstYearInterest = calculateYearlyInterest(initialAmount, monthlyInstallment, interest);

    const initialForecast = {
        amount: initialAmount,
        interest: firstYearInterest,
        installment: 12 * monthlyInstallment,
    };

    let result = [initialForecast];

    result = result.concat(
        accumulate<ILoanForecast>(initialForecast, years, (accumulated) => {
            const remainingLoanAmount = accumulated.amount - accumulated.installment;
            const installment = Math.min(accumulated.installment, remainingLoanAmount);
            const yearlyInterest = calculateYearlyInterest(remainingLoanAmount, installment / 12, interest);

            return {
                amount: remainingLoanAmount,
                interest: yearlyInterest,
                installment,
            };
        }),
    );

    return result;
}

export function forecastYearlyInterests(userData: UserData, years: number): DataPoint {
    const carLoans = userData.loans?.carLoans || [];
    const privateLoans = userData.loans?.privateLoans || [];
    const studentLoans = userData.loans?.studentLoans || [];

    const interestSelector = (fc: ILoanForecast) => fc.interest;

    const carLoanForecasts = sumForecasts(carLoans, years, interestSelector);
    const privateForecasts = sumForecasts(privateLoans, years, interestSelector);
    const studentForecasts = sumForecasts(studentLoans, years, interestSelector);

    return sumDatapoints([carLoanForecasts, privateForecasts, studentForecasts]);
}

export function forecastMonthlyExpense(userData: UserData, years: number): DataPoint {
    const carLoans = userData.loans?.carLoans || [];
    const privateLoans = userData.loans?.privateLoans || [];
    const studentLoans = userData.loans?.studentLoans || [];

    const interestSelector = (fc: ILoanForecast) => Math.round(fc.interest / 12);
    const installmentSelector = (fc: ILoanForecast) => Math.round(fc.installment / 12);

    const carLoanInterestForecast = sumForecasts(carLoans, years, interestSelector);
    const privateLoanInterestForecast = sumForecasts(privateLoans, years, interestSelector);
    const studentLoanInterestForecast = sumForecasts(studentLoans, years, interestSelector);

    const carLoanInstallmentForecast = sumForecasts(carLoans, years, installmentSelector);
    const privateLoanInstallmentForecast = sumForecasts(privateLoans, years, installmentSelector);
    const studentLoanInstallmentForecast = sumForecasts(studentLoans, years, installmentSelector);

    return sumDatapoints([
        carLoanInterestForecast,
        privateLoanInterestForecast,
        studentLoanInterestForecast,
        carLoanInstallmentForecast,
        privateLoanInstallmentForecast,
        studentLoanInstallmentForecast,
    ]);
}

function sumForecasts(loans: Array<Loan>, years: number, selector?: (forecast: ILoanForecast) => number): DataPoint {
    if (!selector) {
        selector = (fc) => fc.amount;
    }

    if (!loans || loans.length === 0) {
        return {
            now: 0,
            future: Array.from({ length: years + 1 }, () => 0),
        };
    }

    const forecasts = loans.map((loan: Loan) => forecastLoan(loan.amount, loan.amortization, loan.interest, years).map(selector));
    const summedForecasts = sumArrays(forecasts);

    return {
        now: summedForecasts[0],
        future: summedForecasts,
    };
}

function forecastHousingLoans(userData: UserData, years: number): DataPoint {
    const forecast = forecastMortgages(userData, years);

    const current = getTotalMortgageAmount(userData);
    return {
        now: current,
        future: forecast.result.map((r) => r.amount),
    };
}

export function forecastLoans(userData: UserData, years: number): ILoansResult {
    if (!userData.loans) {
        return undefined;
    }

    const carLoans = userData.loans.carLoans || [];
    const privateLoans = userData.loans.privateLoans || [];
    const studentLoans = userData.loans.studentLoans || [];

    const housingLoanForecast = forecastHousingLoans(userData, years);
    const carLoanForecasts = sumForecasts(carLoans, years);
    const privateForecasts = sumForecasts(privateLoans, years);
    const studentForecasts = sumForecasts(studentLoans, years);

    const resultBuilder = new ResultBuilder(years);
    if (getTotalMortgageAmount(userData) > 0) {
        resultBuilder.set("housingLoan", housingLoanForecast);
    }
    if (studentLoans.length > 0) {
        resultBuilder.set("studentLoan", studentForecasts);
    }
    if (privateLoans.length > 0) {
        resultBuilder.set("privateLoans", privateForecasts);
    }
    if (carLoans.length > 0) {
        resultBuilder.set("carLoans", carLoanForecasts);
    }

    if (userData.kalp?.additionalHousing.data) {
        resultBuilder.set("additionalHousingLoans", sumForecasts([userData.kalp.additionalHousing.data.mortage], years));
    }

    return resultBuilder.getResult();
}

export function getMaxLoanByDebtRatio(userData: UserData, debtRatio = 5): Maybe<number> {
    const householdIncome = getCurrentHouseholdIncome(userData);
    if (Number.isFinite(householdIncome)) {
        const yearlyHouseholdIncome = (householdIncome as number) * 12;
        return Math.floor(yearlyHouseholdIncome * debtRatio);
    } else {
        return null;
    }
}
