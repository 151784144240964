import * as React from "react";
import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import transitionEnd from "dom-helpers/transitionEnd";
import classNames from "classnames";

import { AccessibleIdiomaticText } from "./accessibility/accessible_components";
import { TooltipEvent } from "../utility/analytics";
import { CSSAnimation, AnimationTransition } from "./wrappers/animations";

interface IContainerHeaderProps extends WithTranslation {
    className?: string;
    header: string;
    showInfo?: boolean;
    onEngagement: (label: TooltipEvent) => void;
}

interface IContainerHeaderState {
    infoVisible: boolean;
}

class ContainerHeaderComponent extends Component<IContainerHeaderProps, IContainerHeaderState> {
    private node: HTMLElement;

    constructor(props) {
        super(props);

        this.state = { infoVisible: false };
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    show() {
        document.addEventListener("click", this.handleDocumentClick);
        this.setState({ infoVisible: true });
        this.props.onEngagement(TooltipEvent.CHART_INFO_BUTTON);
    }

    hide() {
        document.removeEventListener("click", this.handleDocumentClick);
        this.setState({ infoVisible: false });
    }

    toggle(event) {
        event.stopPropagation();
        if (this.state.infoVisible) {
            this.hide();
        } else {
            this.show();
        }
    }

    handleDocumentClick(event) {
        if (this.node && !this.node.contains(event.target)) {
            this.hide();
        }
    }

    render() {
        const { children, className, header, showInfo, t } = this.props;
        const { infoVisible } = this.state;
        return (
            <div>
                <div className={className}>
                    <h3>{header}</h3>
                    {showInfo && (
                        <AnimationTransition in={infoVisible} timeout={250}>
                            {(state) => (
                                <div className="icon-element-container">
                                    <AccessibleIdiomaticText
                                        aria-label={t("aria:info-toggle")}
                                        aria-expanded={infoVisible}
                                        onClick={this.toggle.bind(this)}
                                        className={classNames("icon-outline", state, {
                                            "icon-info": !infoVisible || state === "entering",
                                            "icon-cross": infoVisible && state === "entered",
                                            active: infoVisible,
                                        })}
                                    ></AccessibleIdiomaticText>
                                </div>
                            )}
                        </AnimationTransition>
                    )}
                </div>
                <CSSAnimation in={infoVisible} addEndListener={transitionEnd}>
                    <div className="information-overlay" ref={(el) => (this.node = el)}>
                        <div className="information-container">{children}</div>
                    </div>
                </CSSAnimation>
            </div>
        );
    }
}

export default withTranslation()(ContainerHeaderComponent);
