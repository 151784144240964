// Tax table 32 for 2021
export default [
    {
        table: 32,
        from: 0,
        to: 1600,
        value: 0,
        retirement: 0,
    },
    {
        table: 32,
        from: 1601,
        to: 1700,
        value: 117,
        retirement: 0,
    },
    {
        table: 32,
        from: 1701,
        to: 1800,
        value: 127,
        retirement: 0,
    },
    {
        table: 32,
        from: 1801,
        to: 1900,
        value: 138,
        retirement: 0,
    },
    {
        table: 32,
        from: 1901,
        to: 2000,
        value: 148,
        retirement: 0,
    },
    {
        table: 32,
        from: 2001,
        to: 2100,
        value: 159,
        retirement: 0,
    },
    {
        table: 32,
        from: 2101,
        to: 2200,
        value: 161,
        retirement: 0,
    },
    {
        table: 32,
        from: 2201,
        to: 2300,
        value: 171,
        retirement: 0,
    },
    {
        table: 32,
        from: 2301,
        to: 2400,
        value: 182,
        retirement: 0,
    },
    {
        table: 32,
        from: 2401,
        to: 2500,
        value: 192,
        retirement: 0,
    },
    {
        table: 32,
        from: 2501,
        to: 2600,
        value: 203,
        retirement: 0,
    },
    {
        table: 32,
        from: 2601,
        to: 2700,
        value: 213,
        retirement: 0,
    },
    {
        table: 32,
        from: 2701,
        to: 2800,
        value: 224,
        retirement: 0,
    },
    {
        table: 32,
        from: 2801,
        to: 2900,
        value: 226,
        retirement: 0,
    },
    {
        table: 32,
        from: 2901,
        to: 3000,
        value: 236,
        retirement: 0,
    },
    {
        table: 32,
        from: 3001,
        to: 3100,
        value: 247,
        retirement: 0,
    },
    {
        table: 32,
        from: 3101,
        to: 3200,
        value: 257,
        retirement: 0,
    },
    {
        table: 32,
        from: 3201,
        to: 3300,
        value: 268,
        retirement: 0,
    },
    {
        table: 32,
        from: 3301,
        to: 3400,
        value: 278,
        retirement: 0,
    },
    {
        table: 32,
        from: 3401,
        to: 3500,
        value: 280,
        retirement: 0,
    },
    {
        table: 32,
        from: 3501,
        to: 3600,
        value: 291,
        retirement: 0,
    },
    {
        table: 32,
        from: 3601,
        to: 3700,
        value: 301,
        retirement: 0,
    },
    {
        table: 32,
        from: 3701,
        to: 3800,
        value: 312,
        retirement: 0,
    },
    {
        table: 32,
        from: 3801,
        to: 3900,
        value: 322,
        retirement: 0,
    },
    {
        table: 32,
        from: 3901,
        to: 4000,
        value: 333,
        retirement: 0,
    },
    {
        table: 32,
        from: 4001,
        to: 4100,
        value: 334,
        retirement: 0,
    },
    {
        table: 32,
        from: 4101,
        to: 4200,
        value: 344,
        retirement: 0,
    },
    {
        table: 32,
        from: 4201,
        to: 4300,
        value: 354,
        retirement: 0,
    },
    {
        table: 32,
        from: 4301,
        to: 4400,
        value: 365,
        retirement: 0,
    },
    {
        table: 32,
        from: 4401,
        to: 4500,
        value: 374,
        retirement: 21,
    },
    {
        table: 32,
        from: 4501,
        to: 4600,
        value: 385,
        retirement: 46,
    },
    {
        table: 32,
        from: 4601,
        to: 4700,
        value: 386,
        retirement: 71,
    },
    {
        table: 32,
        from: 4701,
        to: 4800,
        value: 396,
        retirement: 96,
    },
    {
        table: 32,
        from: 4801,
        to: 4900,
        value: 407,
        retirement: 120,
    },
    {
        table: 32,
        from: 4901,
        to: 5000,
        value: 416,
        retirement: 145,
    },
    {
        table: 32,
        from: 5001,
        to: 5100,
        value: 427,
        retirement: 170,
    },
    {
        table: 32,
        from: 5101,
        to: 5200,
        value: 437,
        retirement: 192,
    },
    {
        table: 32,
        from: 5201,
        to: 5300,
        value: 447,
        retirement: 217,
    },
    {
        table: 32,
        from: 5301,
        to: 5400,
        value: 448,
        retirement: 241,
    },
    {
        table: 32,
        from: 5401,
        to: 5500,
        value: 458,
        retirement: 266,
    },
    {
        table: 32,
        from: 5501,
        to: 5600,
        value: 480,
        retirement: 291,
    },
    {
        table: 32,
        from: 5601,
        to: 5700,
        value: 501,
        retirement: 316,
    },
    {
        table: 32,
        from: 5701,
        to: 5800,
        value: 523,
        retirement: 340,
    },
    {
        table: 32,
        from: 5801,
        to: 5900,
        value: 544,
        retirement: 365,
    },
    {
        table: 32,
        from: 5901,
        to: 6000,
        value: 566,
        retirement: 390,
    },
    {
        table: 32,
        from: 6001,
        to: 6100,
        value: 587,
        retirement: 415,
    },
    {
        table: 32,
        from: 6101,
        to: 6200,
        value: 609,
        retirement: 439,
    },
    {
        table: 32,
        from: 6201,
        to: 6300,
        value: 630,
        retirement: 464,
    },
    {
        table: 32,
        from: 6301,
        to: 6400,
        value: 652,
        retirement: 486,
    },
    {
        table: 32,
        from: 6401,
        to: 6500,
        value: 673,
        retirement: 511,
    },
    {
        table: 32,
        from: 6501,
        to: 6600,
        value: 695,
        retirement: 536,
    },
    {
        table: 32,
        from: 6601,
        to: 6700,
        value: 716,
        retirement: 560,
    },
    {
        table: 32,
        from: 6701,
        to: 6800,
        value: 737,
        retirement: 585,
    },
    {
        table: 32,
        from: 6801,
        to: 6900,
        value: 759,
        retirement: 610,
    },
    {
        table: 32,
        from: 6901,
        to: 7000,
        value: 780,
        retirement: 635,
    },
    {
        table: 32,
        from: 7001,
        to: 7100,
        value: 802,
        retirement: 659,
    },
    {
        table: 32,
        from: 7101,
        to: 7200,
        value: 823,
        retirement: 684,
    },
    {
        table: 32,
        from: 7201,
        to: 7300,
        value: 845,
        retirement: 709,
    },
    {
        table: 32,
        from: 7301,
        to: 7400,
        value: 866,
        retirement: 734,
    },
    {
        table: 32,
        from: 7401,
        to: 7500,
        value: 888,
        retirement: 758,
    },
    {
        table: 32,
        from: 7501,
        to: 7600,
        value: 909,
        retirement: 780,
    },
    {
        table: 32,
        from: 7601,
        to: 7700,
        value: 931,
        retirement: 805,
    },
    {
        table: 32,
        from: 7701,
        to: 7800,
        value: 952,
        retirement: 830,
    },
    {
        table: 32,
        from: 7801,
        to: 7900,
        value: 974,
        retirement: 855,
    },
    {
        table: 32,
        from: 7901,
        to: 8000,
        value: 995,
        retirement: 879,
    },
    {
        table: 32,
        from: 8001,
        to: 8100,
        value: 1017,
        retirement: 904,
    },
    {
        table: 32,
        from: 8101,
        to: 8200,
        value: 1038,
        retirement: 929,
    },
    {
        table: 32,
        from: 8201,
        to: 8300,
        value: 1059,
        retirement: 954,
    },
    {
        table: 32,
        from: 8301,
        to: 8400,
        value: 1081,
        retirement: 978,
    },
    {
        table: 32,
        from: 8401,
        to: 8500,
        value: 1102,
        retirement: 1003,
    },
    {
        table: 32,
        from: 8501,
        to: 8600,
        value: 1124,
        retirement: 1028,
    },
    {
        table: 32,
        from: 8601,
        to: 8700,
        value: 1145,
        retirement: 1050,
    },
    {
        table: 32,
        from: 8701,
        to: 8800,
        value: 1167,
        retirement: 1075,
    },
    {
        table: 32,
        from: 8801,
        to: 8900,
        value: 1188,
        retirement: 1100,
    },
    {
        table: 32,
        from: 8901,
        to: 9000,
        value: 1210,
        retirement: 1124,
    },
    {
        table: 32,
        from: 9001,
        to: 9100,
        value: 1231,
        retirement: 1148,
    },
    {
        table: 32,
        from: 9101,
        to: 9200,
        value: 1253,
        retirement: 1172,
    },
    {
        table: 32,
        from: 9201,
        to: 9300,
        value: 1274,
        retirement: 1196,
    },
    {
        table: 32,
        from: 9301,
        to: 9400,
        value: 1296,
        retirement: 1220,
    },
    {
        table: 32,
        from: 9401,
        to: 9500,
        value: 1317,
        retirement: 1245,
    },
    {
        table: 32,
        from: 9501,
        to: 9600,
        value: 1339,
        retirement: 1269,
    },
    {
        table: 32,
        from: 9601,
        to: 9700,
        value: 1360,
        retirement: 1293,
    },
    {
        table: 32,
        from: 9701,
        to: 9800,
        value: 1381,
        retirement: 1317,
    },
    {
        table: 32,
        from: 9801,
        to: 9900,
        value: 1403,
        retirement: 1339,
    },
    {
        table: 32,
        from: 9901,
        to: 10000,
        value: 1424,
        retirement: 1363,
    },
    {
        table: 32,
        from: 10001,
        to: 10100,
        value: 1446,
        retirement: 1387,
    },
    {
        table: 32,
        from: 10101,
        to: 10200,
        value: 1467,
        retirement: 1411,
    },
    {
        table: 32,
        from: 10201,
        to: 10300,
        value: 1489,
        retirement: 1436,
    },
    {
        table: 32,
        from: 10301,
        to: 10400,
        value: 1510,
        retirement: 1460,
    },
    {
        table: 32,
        from: 10401,
        to: 10500,
        value: 1532,
        retirement: 1484,
    },
    {
        table: 32,
        from: 10501,
        to: 10600,
        value: 1553,
        retirement: 1508,
    },
    {
        table: 32,
        from: 10601,
        to: 10700,
        value: 1575,
        retirement: 1532,
    },
    {
        table: 32,
        from: 10701,
        to: 10800,
        value: 1596,
        retirement: 1554,
    },
    {
        table: 32,
        from: 10801,
        to: 10900,
        value: 1618,
        retirement: 1575,
    },
    {
        table: 32,
        from: 10901,
        to: 11000,
        value: 1640,
        retirement: 1597,
    },
    {
        table: 32,
        from: 11001,
        to: 11100,
        value: 1661,
        retirement: 1618,
    },
    {
        table: 32,
        from: 11101,
        to: 11200,
        value: 1683,
        retirement: 1637,
    },
    {
        table: 32,
        from: 11201,
        to: 11300,
        value: 1705,
        retirement: 1658,
    },
    {
        table: 32,
        from: 11301,
        to: 11400,
        value: 1727,
        retirement: 1680,
    },
    {
        table: 32,
        from: 11401,
        to: 11500,
        value: 1748,
        retirement: 1701,
    },
    {
        table: 32,
        from: 11501,
        to: 11600,
        value: 1770,
        retirement: 1723,
    },
    {
        table: 32,
        from: 11601,
        to: 11700,
        value: 1792,
        retirement: 1744,
    },
    {
        table: 32,
        from: 11701,
        to: 11800,
        value: 1814,
        retirement: 1766,
    },
    {
        table: 32,
        from: 11801,
        to: 11900,
        value: 1835,
        retirement: 1787,
    },
    {
        table: 32,
        from: 11901,
        to: 12000,
        value: 1857,
        retirement: 1809,
    },
    {
        table: 32,
        from: 12001,
        to: 12100,
        value: 1879,
        retirement: 1830,
    },
    {
        table: 32,
        from: 12101,
        to: 12200,
        value: 1901,
        retirement: 1852,
    },
    {
        table: 32,
        from: 12201,
        to: 12300,
        value: 1922,
        retirement: 1873,
    },
    {
        table: 32,
        from: 12301,
        to: 12400,
        value: 1944,
        retirement: 1895,
    },
    {
        table: 32,
        from: 12401,
        to: 12500,
        value: 1966,
        retirement: 1914,
    },
    {
        table: 32,
        from: 12501,
        to: 12600,
        value: 1988,
        retirement: 1935,
    },
    {
        table: 32,
        from: 12601,
        to: 12700,
        value: 2010,
        retirement: 1957,
    },
    {
        table: 32,
        from: 12701,
        to: 12800,
        value: 2032,
        retirement: 1976,
    },
    {
        table: 32,
        from: 12801,
        to: 12900,
        value: 2057,
        retirement: 2002,
    },
    {
        table: 32,
        from: 12901,
        to: 13000,
        value: 2086,
        retirement: 2032,
    },
    {
        table: 32,
        from: 13001,
        to: 13100,
        value: 2114,
        retirement: 2059,
    },
    {
        table: 32,
        from: 13101,
        to: 13200,
        value: 2143,
        retirement: 2088,
    },
    {
        table: 32,
        from: 13201,
        to: 13300,
        value: 2171,
        retirement: 2115,
    },
    {
        table: 32,
        from: 13301,
        to: 13400,
        value: 2200,
        retirement: 2145,
    },
    {
        table: 32,
        from: 13401,
        to: 13500,
        value: 2228,
        retirement: 2172,
    },
    {
        table: 32,
        from: 13501,
        to: 13600,
        value: 2256,
        retirement: 2201,
    },
    {
        table: 32,
        from: 13601,
        to: 13700,
        value: 2285,
        retirement: 2228,
    },
    {
        table: 32,
        from: 13701,
        to: 13800,
        value: 2313,
        retirement: 2255,
    },
    {
        table: 32,
        from: 13801,
        to: 13900,
        value: 2342,
        retirement: 2285,
    },
    {
        table: 32,
        from: 13901,
        to: 14000,
        value: 2370,
        retirement: 2312,
    },
    {
        table: 32,
        from: 14001,
        to: 14100,
        value: 2397,
        retirement: 2341,
    },
    {
        table: 32,
        from: 14101,
        to: 14200,
        value: 2424,
        retirement: 2368,
    },
    {
        table: 32,
        from: 14201,
        to: 14300,
        value: 2452,
        retirement: 2398,
    },
    {
        table: 32,
        from: 14301,
        to: 14400,
        value: 2479,
        retirement: 2424,
    },
    {
        table: 32,
        from: 14401,
        to: 14500,
        value: 2507,
        retirement: 2454,
    },
    {
        table: 32,
        from: 14501,
        to: 14600,
        value: 2534,
        retirement: 2481,
    },
    {
        table: 32,
        from: 14601,
        to: 14700,
        value: 2561,
        retirement: 2511,
    },
    {
        table: 32,
        from: 14701,
        to: 14800,
        value: 2589,
        retirement: 2537,
    },
    {
        table: 32,
        from: 14801,
        to: 14900,
        value: 2616,
        retirement: 2567,
    },
    {
        table: 32,
        from: 14901,
        to: 15000,
        value: 2643,
        retirement: 2594,
    },
    {
        table: 32,
        from: 15001,
        to: 15100,
        value: 2671,
        retirement: 2621,
    },
    {
        table: 32,
        from: 15101,
        to: 15200,
        value: 2698,
        retirement: 2650,
    },
    {
        table: 32,
        from: 15201,
        to: 15300,
        value: 2725,
        retirement: 2677,
    },
    {
        table: 32,
        from: 15301,
        to: 15400,
        value: 2753,
        retirement: 2707,
    },
    {
        table: 32,
        from: 15401,
        to: 15500,
        value: 2780,
        retirement: 2733,
    },
    {
        table: 32,
        from: 15501,
        to: 15600,
        value: 2807,
        retirement: 2763,
    },
    {
        table: 32,
        from: 15601,
        to: 15700,
        value: 2835,
        retirement: 2790,
    },
    {
        table: 32,
        from: 15701,
        to: 15800,
        value: 2862,
        retirement: 2819,
    },
    {
        table: 32,
        from: 15801,
        to: 15900,
        value: 2889,
        retirement: 2846,
    },
    {
        table: 32,
        from: 15901,
        to: 16000,
        value: 2917,
        retirement: 2876,
    },
    {
        table: 32,
        from: 16001,
        to: 16100,
        value: 2944,
        retirement: 2903,
    },
    {
        table: 32,
        from: 16101,
        to: 16200,
        value: 2971,
        retirement: 2932,
    },
    {
        table: 32,
        from: 16201,
        to: 16300,
        value: 2999,
        retirement: 2959,
    },
    {
        table: 32,
        from: 16301,
        to: 16400,
        value: 3026,
        retirement: 2989,
    },
    {
        table: 32,
        from: 16401,
        to: 16500,
        value: 3053,
        retirement: 3016,
    },
    {
        table: 32,
        from: 16501,
        to: 16600,
        value: 3081,
        retirement: 3043,
    },
    {
        table: 32,
        from: 16601,
        to: 16700,
        value: 3108,
        retirement: 3072,
    },
    {
        table: 32,
        from: 16701,
        to: 16800,
        value: 3135,
        retirement: 3099,
    },
    {
        table: 32,
        from: 16801,
        to: 16900,
        value: 3163,
        retirement: 3129,
    },
    {
        table: 32,
        from: 16901,
        to: 17000,
        value: 3190,
        retirement: 3155,
    },
    {
        table: 32,
        from: 17001,
        to: 17100,
        value: 3217,
        retirement: 3185,
    },
    {
        table: 32,
        from: 17101,
        to: 17200,
        value: 3245,
        retirement: 3212,
    },
    {
        table: 32,
        from: 17201,
        to: 17300,
        value: 3272,
        retirement: 3241,
    },
    {
        table: 32,
        from: 17301,
        to: 17400,
        value: 3299,
        retirement: 3268,
    },
    {
        table: 32,
        from: 17401,
        to: 17500,
        value: 3327,
        retirement: 3298,
    },
    {
        table: 32,
        from: 17501,
        to: 17600,
        value: 3354,
        retirement: 3325,
    },
    {
        table: 32,
        from: 17601,
        to: 17700,
        value: 3382,
        retirement: 3354,
    },
    {
        table: 32,
        from: 17701,
        to: 17800,
        value: 3409,
        retirement: 3381,
    },
    {
        table: 32,
        from: 17801,
        to: 17900,
        value: 3436,
        retirement: 3408,
    },
    {
        table: 32,
        from: 17901,
        to: 18000,
        value: 3464,
        retirement: 3438,
    },
    {
        table: 32,
        from: 18001,
        to: 18100,
        value: 3491,
        retirement: 3464,
    },
    {
        table: 32,
        from: 18101,
        to: 18200,
        value: 3518,
        retirement: 3494,
    },
    {
        table: 32,
        from: 18201,
        to: 18300,
        value: 3546,
        retirement: 3521,
    },
    {
        table: 32,
        from: 18301,
        to: 18400,
        value: 3573,
        retirement: 3550,
    },
    {
        table: 32,
        from: 18401,
        to: 18500,
        value: 3600,
        retirement: 3577,
    },
    {
        table: 32,
        from: 18501,
        to: 18600,
        value: 3628,
        retirement: 3607,
    },
    {
        table: 32,
        from: 18601,
        to: 18700,
        value: 3655,
        retirement: 3633,
    },
    {
        table: 32,
        from: 18701,
        to: 18800,
        value: 3682,
        retirement: 3661,
    },
    {
        table: 32,
        from: 18801,
        to: 18900,
        value: 3710,
        retirement: 3687,
    },
    {
        table: 32,
        from: 18901,
        to: 19000,
        value: 3737,
        retirement: 3716,
    },
    {
        table: 32,
        from: 19001,
        to: 19100,
        value: 3764,
        retirement: 3742,
    },
    {
        table: 32,
        from: 19101,
        to: 19200,
        value: 3792,
        retirement: 3771,
    },
    {
        table: 32,
        from: 19201,
        to: 19300,
        value: 3819,
        retirement: 3797,
    },
    {
        table: 32,
        from: 19301,
        to: 19400,
        value: 3846,
        retirement: 3823,
    },
    {
        table: 32,
        from: 19401,
        to: 19500,
        value: 3874,
        retirement: 3851,
    },
    {
        table: 32,
        from: 19501,
        to: 19600,
        value: 3901,
        retirement: 3877,
    },
    {
        table: 32,
        from: 19601,
        to: 19700,
        value: 3928,
        retirement: 3906,
    },
    {
        table: 32,
        from: 19701,
        to: 19800,
        value: 3956,
        retirement: 3932,
    },
    {
        table: 32,
        from: 19801,
        to: 19900,
        value: 3983,
        retirement: 3961,
    },
    {
        table: 32,
        from: 19901,
        to: 20000,
        value: 4011,
        retirement: 3987,
    },
    {
        table: 32,
        from: 20001,
        to: 20200,
        value: 4065,
        retirement: 4042,
    },
    {
        table: 32,
        from: 20201,
        to: 20400,
        value: 4120,
        retirement: 4096,
    },
    {
        table: 32,
        from: 20401,
        to: 20600,
        value: 4174,
        retirement: 4151,
    },
    {
        table: 32,
        from: 20601,
        to: 20800,
        value: 4229,
        retirement: 4206,
    },
    {
        table: 32,
        from: 20801,
        to: 21000,
        value: 4284,
        retirement: 4260,
    },
    {
        table: 32,
        from: 21001,
        to: 21200,
        value: 4339,
        retirement: 4315,
    },
    {
        table: 32,
        from: 21201,
        to: 21400,
        value: 4393,
        retirement: 4370,
    },
    {
        table: 32,
        from: 21401,
        to: 21600,
        value: 4448,
        retirement: 4424,
    },
    {
        table: 32,
        from: 21601,
        to: 21800,
        value: 4503,
        retirement: 4479,
    },
    {
        table: 32,
        from: 21801,
        to: 22000,
        value: 4557,
        retirement: 4531,
    },
    {
        table: 32,
        from: 22001,
        to: 22200,
        value: 4613,
        retirement: 4586,
    },
    {
        table: 32,
        from: 22201,
        to: 22400,
        value: 4669,
        retirement: 4640,
    },
    {
        table: 32,
        from: 22401,
        to: 22600,
        value: 4726,
        retirement: 4695,
    },
    {
        table: 32,
        from: 22601,
        to: 22800,
        value: 4782,
        retirement: 4750,
    },
    {
        table: 32,
        from: 22801,
        to: 23000,
        value: 4838,
        retirement: 4805,
    },
    {
        table: 32,
        from: 23001,
        to: 23200,
        value: 4895,
        retirement: 4859,
    },
    {
        table: 32,
        from: 23201,
        to: 23400,
        value: 4951,
        retirement: 4914,
    },
    {
        table: 32,
        from: 23401,
        to: 23600,
        value: 5007,
        retirement: 4969,
    },
    {
        table: 32,
        from: 23601,
        to: 23800,
        value: 5064,
        retirement: 5023,
    },
    {
        table: 32,
        from: 23801,
        to: 24000,
        value: 5120,
        retirement: 5078,
    },
    {
        table: 32,
        from: 24001,
        to: 24200,
        value: 5176,
        retirement: 5133,
    },
    {
        table: 32,
        from: 24201,
        to: 24400,
        value: 5233,
        retirement: 5187,
    },
    {
        table: 32,
        from: 24401,
        to: 24600,
        value: 5289,
        retirement: 5242,
    },
    {
        table: 32,
        from: 24601,
        to: 24800,
        value: 5345,
        retirement: 5294,
    },
    {
        table: 32,
        from: 24801,
        to: 25000,
        value: 5402,
        retirement: 5349,
    },
    {
        table: 32,
        from: 25001,
        to: 25200,
        value: 5458,
        retirement: 5403,
    },
    {
        table: 32,
        from: 25201,
        to: 25400,
        value: 5514,
        retirement: 5458,
    },
    {
        table: 32,
        from: 25401,
        to: 25600,
        value: 5571,
        retirement: 5513,
    },
    {
        table: 32,
        from: 25601,
        to: 25800,
        value: 5627,
        retirement: 5568,
    },
    {
        table: 32,
        from: 25801,
        to: 26000,
        value: 5683,
        retirement: 5622,
    },
    {
        table: 32,
        from: 26001,
        to: 26200,
        value: 5740,
        retirement: 5677,
    },
    {
        table: 32,
        from: 26201,
        to: 26400,
        value: 5796,
        retirement: 5732,
    },
    {
        table: 32,
        from: 26401,
        to: 26600,
        value: 5852,
        retirement: 5786,
    },
    {
        table: 32,
        from: 26601,
        to: 26800,
        value: 5909,
        retirement: 5841,
    },
    {
        table: 32,
        from: 26801,
        to: 27000,
        value: 5965,
        retirement: 5896,
    },
    {
        table: 32,
        from: 27001,
        to: 27200,
        value: 6021,
        retirement: 5950,
    },
    {
        table: 32,
        from: 27201,
        to: 27400,
        value: 6078,
        retirement: 6005,
    },
    {
        table: 32,
        from: 27401,
        to: 27600,
        value: 6134,
        retirement: 6057,
    },
    {
        table: 32,
        from: 27601,
        to: 27800,
        value: 6190,
        retirement: 6112,
    },
    {
        table: 32,
        from: 27801,
        to: 28000,
        value: 6247,
        retirement: 6166,
    },
    {
        table: 32,
        from: 28001,
        to: 28200,
        value: 6303,
        retirement: 6221,
    },
    {
        table: 32,
        from: 28201,
        to: 28400,
        value: 6359,
        retirement: 6276,
    },
    {
        table: 32,
        from: 28401,
        to: 28600,
        value: 6416,
        retirement: 6331,
    },
    {
        table: 32,
        from: 28601,
        to: 28800,
        value: 6472,
        retirement: 6385,
    },
    {
        table: 32,
        from: 28801,
        to: 29000,
        value: 6528,
        retirement: 6441,
    },
    {
        table: 32,
        from: 29001,
        to: 29200,
        value: 6585,
        retirement: 6497,
    },
    {
        table: 32,
        from: 29201,
        to: 29400,
        value: 6641,
        retirement: 6553,
    },
    {
        table: 32,
        from: 29401,
        to: 29600,
        value: 6697,
        retirement: 6609,
    },
    {
        table: 32,
        from: 29601,
        to: 29800,
        value: 6754,
        retirement: 6665,
    },
    {
        table: 32,
        from: 29801,
        to: 30000,
        value: 6810,
        retirement: 6721,
    },
    {
        table: 32,
        from: 30001,
        to: 30200,
        value: 6866,
        retirement: 6777,
    },
    {
        table: 32,
        from: 30201,
        to: 30400,
        value: 6923,
        retirement: 6831,
    },
    {
        table: 32,
        from: 30401,
        to: 30600,
        value: 6979,
        retirement: 6887,
    },
    {
        table: 32,
        from: 30601,
        to: 30800,
        value: 7035,
        retirement: 6943,
    },
    {
        table: 32,
        from: 30801,
        to: 31000,
        value: 7092,
        retirement: 6999,
    },
    {
        table: 32,
        from: 31001,
        to: 31200,
        value: 7148,
        retirement: 7055,
    },
    {
        table: 32,
        from: 31201,
        to: 31400,
        value: 7204,
        retirement: 7111,
    },
    {
        table: 32,
        from: 31401,
        to: 31600,
        value: 7260,
        retirement: 7167,
    },
    {
        table: 32,
        from: 31601,
        to: 31800,
        value: 7316,
        retirement: 7223,
    },
    {
        table: 32,
        from: 31801,
        to: 32000,
        value: 7373,
        retirement: 7279,
    },
    {
        table: 32,
        from: 32001,
        to: 32200,
        value: 7434,
        retirement: 7340,
    },
    {
        table: 32,
        from: 32201,
        to: 32400,
        value: 7498,
        retirement: 7404,
    },
    {
        table: 32,
        from: 32401,
        to: 32600,
        value: 7562,
        retirement: 7468,
    },
    {
        table: 32,
        from: 32601,
        to: 32800,
        value: 7626,
        retirement: 7532,
    },
    {
        table: 32,
        from: 32801,
        to: 33000,
        value: 7690,
        retirement: 7596,
    },
    {
        table: 32,
        from: 33001,
        to: 33200,
        value: 7754,
        retirement: 7660,
    },
    {
        table: 32,
        from: 33201,
        to: 33400,
        value: 7818,
        retirement: 7724,
    },
    {
        table: 32,
        from: 33401,
        to: 33600,
        value: 7882,
        retirement: 7788,
    },
    {
        table: 32,
        from: 33601,
        to: 33800,
        value: 7946,
        retirement: 7852,
    },
    {
        table: 32,
        from: 33801,
        to: 34000,
        value: 8010,
        retirement: 7916,
    },
    {
        table: 32,
        from: 34001,
        to: 34200,
        value: 8074,
        retirement: 7980,
    },
    {
        table: 32,
        from: 34201,
        to: 34400,
        value: 8138,
        retirement: 8044,
    },
    {
        table: 32,
        from: 34401,
        to: 34600,
        value: 8202,
        retirement: 8108,
    },
    {
        table: 32,
        from: 34601,
        to: 34800,
        value: 8266,
        retirement: 8172,
    },
    {
        table: 32,
        from: 34801,
        to: 35000,
        value: 8330,
        retirement: 8236,
    },
    {
        table: 32,
        from: 35001,
        to: 35200,
        value: 8394,
        retirement: 8300,
    },
    {
        table: 32,
        from: 35201,
        to: 35400,
        value: 8458,
        retirement: 8364,
    },
    {
        table: 32,
        from: 35401,
        to: 35600,
        value: 8522,
        retirement: 8428,
    },
    {
        table: 32,
        from: 35601,
        to: 35800,
        value: 8586,
        retirement: 8492,
    },
    {
        table: 32,
        from: 35801,
        to: 36000,
        value: 8650,
        retirement: 8556,
    },
    {
        table: 32,
        from: 36001,
        to: 36200,
        value: 8714,
        retirement: 8620,
    },
    {
        table: 32,
        from: 36201,
        to: 36400,
        value: 8778,
        retirement: 8684,
    },
    {
        table: 32,
        from: 36401,
        to: 36600,
        value: 8842,
        retirement: 8748,
    },
    {
        table: 32,
        from: 36601,
        to: 36800,
        value: 8906,
        retirement: 8812,
    },
    {
        table: 32,
        from: 36801,
        to: 37000,
        value: 8970,
        retirement: 8876,
    },
    {
        table: 32,
        from: 37001,
        to: 37200,
        value: 9034,
        retirement: 8940,
    },
    {
        table: 32,
        from: 37201,
        to: 37400,
        value: 9098,
        retirement: 9004,
    },
    {
        table: 32,
        from: 37401,
        to: 37600,
        value: 9162,
        retirement: 9068,
    },
    {
        table: 32,
        from: 37601,
        to: 37800,
        value: 9226,
        retirement: 9132,
    },
    {
        table: 32,
        from: 37801,
        to: 38000,
        value: 9290,
        retirement: 9196,
    },
    {
        table: 32,
        from: 38001,
        to: 38200,
        value: 9354,
        retirement: 9260,
    },
    {
        table: 32,
        from: 38201,
        to: 38400,
        value: 9418,
        retirement: 9324,
    },
    {
        table: 32,
        from: 38401,
        to: 38600,
        value: 9482,
        retirement: 9388,
    },
    {
        table: 32,
        from: 38601,
        to: 38800,
        value: 9546,
        retirement: 9452,
    },
    {
        table: 32,
        from: 38801,
        to: 39000,
        value: 9610,
        retirement: 9516,
    },
    {
        table: 32,
        from: 39001,
        to: 39200,
        value: 9674,
        retirement: 9580,
    },
    {
        table: 32,
        from: 39201,
        to: 39400,
        value: 9738,
        retirement: 9644,
    },
    {
        table: 32,
        from: 39401,
        to: 39600,
        value: 9802,
        retirement: 9708,
    },
    {
        table: 32,
        from: 39601,
        to: 39800,
        value: 9866,
        retirement: 9772,
    },
    {
        table: 32,
        from: 39801,
        to: 40000,
        value: 9930,
        retirement: 9836,
    },
    {
        table: 32,
        from: 40001,
        to: 40200,
        value: 9994,
        retirement: 9900,
    },
    {
        table: 32,
        from: 40201,
        to: 40400,
        value: 10058,
        retirement: 9964,
    },
    {
        table: 32,
        from: 40401,
        to: 40600,
        value: 10122,
        retirement: 10028,
    },
    {
        table: 32,
        from: 40601,
        to: 40800,
        value: 10186,
        retirement: 10092,
    },
    {
        table: 32,
        from: 40801,
        to: 41000,
        value: 10250,
        retirement: 10156,
    },
    {
        table: 32,
        from: 41001,
        to: 41200,
        value: 10314,
        retirement: 10220,
    },
    {
        table: 32,
        from: 41201,
        to: 41400,
        value: 10378,
        retirement: 10284,
    },
    {
        table: 32,
        from: 41401,
        to: 41600,
        value: 10442,
        retirement: 10348,
    },
    {
        table: 32,
        from: 41601,
        to: 41800,
        value: 10506,
        retirement: 10412,
    },
    {
        table: 32,
        from: 41801,
        to: 42000,
        value: 10570,
        retirement: 10476,
    },
    {
        table: 32,
        from: 42001,
        to: 42200,
        value: 10634,
        retirement: 10540,
    },
    {
        table: 32,
        from: 42201,
        to: 42400,
        value: 10698,
        retirement: 10604,
    },
    {
        table: 32,
        from: 42401,
        to: 42600,
        value: 10762,
        retirement: 10668,
    },
    {
        table: 32,
        from: 42601,
        to: 42800,
        value: 10826,
        retirement: 10732,
    },
    {
        table: 32,
        from: 42801,
        to: 43000,
        value: 10890,
        retirement: 10796,
    },
    {
        table: 32,
        from: 43001,
        to: 43200,
        value: 10954,
        retirement: 10860,
    },
    {
        table: 32,
        from: 43201,
        to: 43400,
        value: 11018,
        retirement: 10924,
    },
    {
        table: 32,
        from: 43401,
        to: 43600,
        value: 11082,
        retirement: 10988,
    },
    {
        table: 32,
        from: 43601,
        to: 43800,
        value: 11146,
        retirement: 11052,
    },
    {
        table: 32,
        from: 43801,
        to: 44000,
        value: 11210,
        retirement: 11116,
    },
    {
        table: 32,
        from: 44001,
        to: 44200,
        value: 11274,
        retirement: 11180,
    },
    {
        table: 32,
        from: 44201,
        to: 44400,
        value: 11338,
        retirement: 11244,
    },
    {
        table: 32,
        from: 44401,
        to: 44600,
        value: 11402,
        retirement: 11308,
    },
    {
        table: 32,
        from: 44601,
        to: 44800,
        value: 11473,
        retirement: 11383,
    },
    {
        table: 32,
        from: 44801,
        to: 45000,
        value: 11577,
        retirement: 11487,
    },
    {
        table: 32,
        from: 45001,
        to: 45200,
        value: 11681,
        retirement: 11591,
    },
    {
        table: 32,
        from: 45201,
        to: 45400,
        value: 11785,
        retirement: 11695,
    },
    {
        table: 32,
        from: 45401,
        to: 45600,
        value: 11889,
        retirement: 11799,
    },
    {
        table: 32,
        from: 45601,
        to: 45800,
        value: 11993,
        retirement: 11903,
    },
    {
        table: 32,
        from: 45801,
        to: 46000,
        value: 12097,
        retirement: 12004,
    },
    {
        table: 32,
        from: 46001,
        to: 46200,
        value: 12201,
        retirement: 12108,
    },
    {
        table: 32,
        from: 46201,
        to: 46400,
        value: 12305,
        retirement: 12212,
    },
    {
        table: 32,
        from: 46401,
        to: 46600,
        value: 12409,
        retirement: 12316,
    },
    {
        table: 32,
        from: 46601,
        to: 46800,
        value: 12513,
        retirement: 12420,
    },
    {
        table: 32,
        from: 46801,
        to: 47000,
        value: 12617,
        retirement: 12524,
    },
    {
        table: 32,
        from: 47001,
        to: 47200,
        value: 12721,
        retirement: 12628,
    },
    {
        table: 32,
        from: 47201,
        to: 47400,
        value: 12825,
        retirement: 12732,
    },
    {
        table: 32,
        from: 47401,
        to: 47600,
        value: 12929,
        retirement: 12836,
    },
    {
        table: 32,
        from: 47601,
        to: 47800,
        value: 13033,
        retirement: 12937,
    },
    {
        table: 32,
        from: 47801,
        to: 48000,
        value: 13137,
        retirement: 13041,
    },
    {
        table: 32,
        from: 48001,
        to: 48200,
        value: 13241,
        retirement: 13145,
    },
    {
        table: 32,
        from: 48201,
        to: 48400,
        value: 13345,
        retirement: 13249,
    },
    {
        table: 32,
        from: 48401,
        to: 48600,
        value: 13449,
        retirement: 13353,
    },
    {
        table: 32,
        from: 48601,
        to: 48800,
        value: 13553,
        retirement: 13457,
    },
    {
        table: 32,
        from: 48801,
        to: 49000,
        value: 13657,
        retirement: 13561,
    },
    {
        table: 32,
        from: 49001,
        to: 49200,
        value: 13761,
        retirement: 13665,
    },
    {
        table: 32,
        from: 49201,
        to: 49400,
        value: 13865,
        retirement: 13767,
    },
    {
        table: 32,
        from: 49401,
        to: 49600,
        value: 13969,
        retirement: 13871,
    },
    {
        table: 32,
        from: 49601,
        to: 49800,
        value: 14073,
        retirement: 13972,
    },
    {
        table: 32,
        from: 49801,
        to: 50000,
        value: 14177,
        retirement: 14076,
    },
    {
        table: 32,
        from: 50001,
        to: 50200,
        value: 14281,
        retirement: 14180,
    },
    {
        table: 32,
        from: 50201,
        to: 50400,
        value: 14385,
        retirement: 14284,
    },
    {
        table: 32,
        from: 50401,
        to: 50600,
        value: 14489,
        retirement: 14388,
    },
    {
        table: 32,
        from: 50601,
        to: 50800,
        value: 14593,
        retirement: 14492,
    },
    {
        table: 32,
        from: 50801,
        to: 51000,
        value: 14697,
        retirement: 14596,
    },
    {
        table: 32,
        from: 51001,
        to: 51200,
        value: 14801,
        retirement: 14700,
    },
    {
        table: 32,
        from: 51201,
        to: 51400,
        value: 14905,
        retirement: 14804,
    },
    {
        table: 32,
        from: 51401,
        to: 51600,
        value: 15009,
        retirement: 14908,
    },
    {
        table: 32,
        from: 51601,
        to: 51800,
        value: 15113,
        retirement: 15012,
    },
    {
        table: 32,
        from: 51801,
        to: 52000,
        value: 15217,
        retirement: 15116,
    },
    {
        table: 32,
        from: 52001,
        to: 52200,
        value: 15321,
        retirement: 15220,
    },
    {
        table: 32,
        from: 52201,
        to: 52400,
        value: 15425,
        retirement: 15324,
    },
    {
        table: 32,
        from: 52401,
        to: 52600,
        value: 15529,
        retirement: 15428,
    },
    {
        table: 32,
        from: 52601,
        to: 52800,
        value: 15633,
        retirement: 15532,
    },
    {
        table: 32,
        from: 52801,
        to: 53000,
        value: 15737,
        retirement: 15636,
    },
    {
        table: 32,
        from: 53001,
        to: 53200,
        value: 15841,
        retirement: 15740,
    },
    {
        table: 32,
        from: 53201,
        to: 53400,
        value: 15945,
        retirement: 15844,
    },
    {
        table: 32,
        from: 53401,
        to: 53600,
        value: 16049,
        retirement: 15948,
    },
    {
        table: 32,
        from: 53601,
        to: 53800,
        value: 16155,
        retirement: 16052,
    },
    {
        table: 32,
        from: 53801,
        to: 54000,
        value: 16265,
        retirement: 16165,
    },
    {
        table: 32,
        from: 54001,
        to: 54200,
        value: 16375,
        retirement: 16273,
    },
    {
        table: 32,
        from: 54201,
        to: 54400,
        value: 16485,
        retirement: 16381,
    },
    {
        table: 32,
        from: 54401,
        to: 54600,
        value: 16595,
        retirement: 16494,
    },
    {
        table: 32,
        from: 54601,
        to: 54800,
        value: 16705,
        retirement: 16602,
    },
    {
        table: 32,
        from: 54801,
        to: 55000,
        value: 16815,
        retirement: 16715,
    },
    {
        table: 32,
        from: 55001,
        to: 55200,
        value: 16925,
        retirement: 16823,
    },
    {
        table: 32,
        from: 55201,
        to: 55400,
        value: 17035,
        retirement: 16932,
    },
    {
        table: 32,
        from: 55401,
        to: 55600,
        value: 17145,
        retirement: 17044,
    },
    {
        table: 32,
        from: 55601,
        to: 55800,
        value: 17255,
        retirement: 17153,
    },
    {
        table: 32,
        from: 55801,
        to: 56000,
        value: 17365,
        retirement: 17261,
    },
    {
        table: 32,
        from: 56001,
        to: 56200,
        value: 17475,
        retirement: 17374,
    },
    {
        table: 32,
        from: 56201,
        to: 56400,
        value: 17585,
        retirement: 17482,
    },
    {
        table: 32,
        from: 56401,
        to: 56600,
        value: 17695,
        retirement: 17595,
    },
    {
        table: 32,
        from: 56601,
        to: 56800,
        value: 17805,
        retirement: 17703,
    },
    {
        table: 32,
        from: 56801,
        to: 57000,
        value: 17915,
        retirement: 17811,
    },
    {
        table: 32,
        from: 57001,
        to: 57200,
        value: 18025,
        retirement: 17924,
    },
    {
        table: 32,
        from: 57201,
        to: 57400,
        value: 18135,
        retirement: 18032,
    },
    {
        table: 32,
        from: 57401,
        to: 57600,
        value: 18245,
        retirement: 18145,
    },
    {
        table: 32,
        from: 57601,
        to: 57800,
        value: 18355,
        retirement: 18253,
    },
    {
        table: 32,
        from: 57801,
        to: 58000,
        value: 18465,
        retirement: 18362,
    },
    {
        table: 32,
        from: 58001,
        to: 58200,
        value: 18575,
        retirement: 18474,
    },
    {
        table: 32,
        from: 58201,
        to: 58400,
        value: 18685,
        retirement: 18583,
    },
    {
        table: 32,
        from: 58401,
        to: 58600,
        value: 18795,
        retirement: 18691,
    },
    {
        table: 32,
        from: 58601,
        to: 58800,
        value: 18905,
        retirement: 18804,
    },
    {
        table: 32,
        from: 58801,
        to: 59000,
        value: 19015,
        retirement: 18912,
    },
    {
        table: 32,
        from: 59001,
        to: 59200,
        value: 19125,
        retirement: 19025,
    },
    {
        table: 32,
        from: 59201,
        to: 59400,
        value: 19235,
        retirement: 19133,
    },
    {
        table: 32,
        from: 59401,
        to: 59600,
        value: 19345,
        retirement: 19241,
    },
    {
        table: 32,
        from: 59601,
        to: 59800,
        value: 19455,
        retirement: 19354,
    },
    {
        table: 32,
        from: 59801,
        to: 60000,
        value: 19565,
        retirement: 19462,
    },
    {
        table: 32,
        from: 60001,
        to: 60200,
        value: 19675,
        retirement: 19571,
    },
    {
        table: 32,
        from: 60201,
        to: 60400,
        value: 19785,
        retirement: 19683,
    },
    {
        table: 32,
        from: 60401,
        to: 60600,
        value: 19895,
        retirement: 19792,
    },
    {
        table: 32,
        from: 60601,
        to: 60800,
        value: 20005,
        retirement: 19904,
    },
    {
        table: 32,
        from: 60801,
        to: 61000,
        value: 20115,
        retirement: 20013,
    },
    {
        table: 32,
        from: 61001,
        to: 61200,
        value: 20225,
        retirement: 20121,
    },
    {
        table: 32,
        from: 61201,
        to: 61400,
        value: 20335,
        retirement: 20234,
    },
    {
        table: 32,
        from: 61401,
        to: 61600,
        value: 20445,
        retirement: 20342,
    },
    {
        table: 32,
        from: 61601,
        to: 61800,
        value: 20555,
        retirement: 20450,
    },
    {
        table: 32,
        from: 61801,
        to: 62000,
        value: 20665,
        retirement: 20563,
    },
    {
        table: 32,
        from: 62001,
        to: 62200,
        value: 20775,
        retirement: 20671,
    },
    {
        table: 32,
        from: 62201,
        to: 62400,
        value: 20885,
        retirement: 20784,
    },
    {
        table: 32,
        from: 62401,
        to: 62600,
        value: 20995,
        retirement: 20892,
    },
    {
        table: 32,
        from: 62601,
        to: 62800,
        value: 21105,
        retirement: 21001,
    },
    {
        table: 32,
        from: 62801,
        to: 63000,
        value: 21215,
        retirement: 21113,
    },
    {
        table: 32,
        from: 63001,
        to: 63200,
        value: 21325,
        retirement: 21222,
    },
    {
        table: 32,
        from: 63201,
        to: 63400,
        value: 21435,
        retirement: 21330,
    },
    {
        table: 32,
        from: 63401,
        to: 63600,
        value: 21545,
        retirement: 21443,
    },
    {
        table: 32,
        from: 63601,
        to: 63800,
        value: 21655,
        retirement: 21551,
    },
    {
        table: 32,
        from: 63801,
        to: 64000,
        value: 21765,
        retirement: 21664,
    },
    {
        table: 32,
        from: 64001,
        to: 64200,
        value: 21875,
        retirement: 21772,
    },
    {
        table: 32,
        from: 64201,
        to: 64400,
        value: 21985,
        retirement: 21880,
    },
    {
        table: 32,
        from: 64401,
        to: 64600,
        value: 22095,
        retirement: 21993,
    },
    {
        table: 32,
        from: 64601,
        to: 64800,
        value: 22205,
        retirement: 22101,
    },
    {
        table: 32,
        from: 64801,
        to: 65000,
        value: 22315,
        retirement: 22210,
    },
    {
        table: 32,
        from: 65001,
        to: 65200,
        value: 22425,
        retirement: 22322,
    },
    {
        table: 32,
        from: 65201,
        to: 65400,
        value: 22535,
        retirement: 22431,
    },
    {
        table: 32,
        from: 65401,
        to: 65600,
        value: 22645,
        retirement: 22543,
    },
    {
        table: 32,
        from: 65601,
        to: 65800,
        value: 22755,
        retirement: 22652,
    },
    {
        table: 32,
        from: 65801,
        to: 66000,
        value: 22865,
        retirement: 22760,
    },
    {
        table: 32,
        from: 66001,
        to: 66200,
        value: 22975,
        retirement: 22873,
    },
    {
        table: 32,
        from: 66201,
        to: 66400,
        value: 23085,
        retirement: 22981,
    },
    {
        table: 32,
        from: 66401,
        to: 66600,
        value: 23195,
        retirement: 23089,
    },
    {
        table: 32,
        from: 66601,
        to: 66800,
        value: 23305,
        retirement: 23202,
    },
    {
        table: 32,
        from: 66801,
        to: 67000,
        value: 23415,
        retirement: 23310,
    },
    {
        table: 32,
        from: 67001,
        to: 67200,
        value: 23525,
        retirement: 23423,
    },
    {
        table: 32,
        from: 67201,
        to: 67400,
        value: 23635,
        retirement: 23531,
    },
    {
        table: 32,
        from: 67401,
        to: 67600,
        value: 23745,
        retirement: 23640,
    },
    {
        table: 32,
        from: 67601,
        to: 67800,
        value: 23855,
        retirement: 23752,
    },
    {
        table: 32,
        from: 67801,
        to: 68000,
        value: 23965,
        retirement: 23861,
    },
    {
        table: 32,
        from: 68001,
        to: 68200,
        value: 24075,
        retirement: 23973,
    },
    {
        table: 32,
        from: 68201,
        to: 68400,
        value: 24185,
        retirement: 24082,
    },
    {
        table: 32,
        from: 68401,
        to: 68600,
        value: 24295,
        retirement: 24190,
    },
    {
        table: 32,
        from: 68601,
        to: 68800,
        value: 24405,
        retirement: 24303,
    },
    {
        table: 32,
        from: 68801,
        to: 69000,
        value: 24515,
        retirement: 24411,
    },
    {
        table: 32,
        from: 69001,
        to: 69200,
        value: 24625,
        retirement: 24519,
    },
    {
        table: 32,
        from: 69201,
        to: 69400,
        value: 24735,
        retirement: 24632,
    },
    {
        table: 32,
        from: 69401,
        to: 69600,
        value: 24845,
        retirement: 24740,
    },
    {
        table: 32,
        from: 69601,
        to: 69800,
        value: 24955,
        retirement: 24853,
    },
    {
        table: 32,
        from: 69801,
        to: 70000,
        value: 25065,
        retirement: 24961,
    },
    {
        table: 32,
        from: 70001,
        to: 70200,
        value: 25175,
        retirement: 25070,
    },
    {
        table: 32,
        from: 70201,
        to: 70400,
        value: 25285,
        retirement: 25182,
    },
    {
        table: 32,
        from: 70401,
        to: 70600,
        value: 25395,
        retirement: 25291,
    },
    {
        table: 32,
        from: 70601,
        to: 70800,
        value: 25505,
        retirement: 25399,
    },
    {
        table: 32,
        from: 70801,
        to: 71000,
        value: 25615,
        retirement: 25512,
    },
    {
        table: 32,
        from: 71001,
        to: 71200,
        value: 25725,
        retirement: 25620,
    },
    {
        table: 32,
        from: 71201,
        to: 71400,
        value: 25835,
        retirement: 25733,
    },
    {
        table: 32,
        from: 71401,
        to: 71600,
        value: 25945,
        retirement: 25841,
    },
    {
        table: 32,
        from: 71601,
        to: 71800,
        value: 26055,
        retirement: 25949,
    },
    {
        table: 32,
        from: 71801,
        to: 72000,
        value: 26165,
        retirement: 26062,
    },
    {
        table: 32,
        from: 72001,
        to: 72200,
        value: 26275,
        retirement: 26170,
    },
    {
        table: 32,
        from: 72201,
        to: 72400,
        value: 26385,
        retirement: 26279,
    },
    {
        table: 32,
        from: 72401,
        to: 72600,
        value: 26495,
        retirement: 26391,
    },
    {
        table: 32,
        from: 72601,
        to: 72800,
        value: 26605,
        retirement: 26500,
    },
    {
        table: 32,
        from: 72801,
        to: 73000,
        value: 26715,
        retirement: 26612,
    },
    {
        table: 32,
        from: 73001,
        to: 73200,
        value: 26825,
        retirement: 26721,
    },
    {
        table: 32,
        from: 73201,
        to: 73400,
        value: 26935,
        retirement: 26829,
    },
    {
        table: 32,
        from: 73401,
        to: 73600,
        value: 27045,
        retirement: 26942,
    },
    {
        table: 32,
        from: 73601,
        to: 73800,
        value: 27155,
        retirement: 27050,
    },
    {
        table: 32,
        from: 73801,
        to: 74000,
        value: 27265,
        retirement: 27158,
    },
    {
        table: 32,
        from: 74001,
        to: 74200,
        value: 27375,
        retirement: 27271,
    },
    {
        table: 32,
        from: 74201,
        to: 74400,
        value: 27485,
        retirement: 27379,
    },
    {
        table: 32,
        from: 74401,
        to: 74600,
        value: 27595,
        retirement: 27492,
    },
    {
        table: 32,
        from: 74601,
        to: 74800,
        value: 27705,
        retirement: 27600,
    },
    {
        table: 32,
        from: 74801,
        to: 75000,
        value: 27815,
        retirement: 27709,
    },
    {
        table: 32,
        from: 75001,
        to: 75200,
        value: 27925,
        retirement: 27821,
    },
    {
        table: 32,
        from: 75201,
        to: 75400,
        value: 28035,
        retirement: 27930,
    },
    {
        table: 32,
        from: 75401,
        to: 75600,
        value: 28145,
        retirement: 28038,
    },
    {
        table: 32,
        from: 75601,
        to: 75800,
        value: 28255,
        retirement: 28151,
    },
    {
        table: 32,
        from: 75801,
        to: 76000,
        value: 28365,
        retirement: 28259,
    },
    {
        table: 32,
        from: 76001,
        to: 76200,
        value: 28475,
        retirement: 28372,
    },
    {
        table: 32,
        from: 76201,
        to: 76400,
        value: 28585,
        retirement: 28480,
    },
    {
        table: 32,
        from: 76401,
        to: 76600,
        value: 28695,
        retirement: 28588,
    },
    {
        table: 32,
        from: 76601,
        to: 76800,
        value: 28805,
        retirement: 28701,
    },
    {
        table: 32,
        from: 76801,
        to: 77000,
        value: 28915,
        retirement: 28809,
    },
    {
        table: 32,
        from: 77001,
        to: 77200,
        value: 29025,
        retirement: 28922,
    },
    {
        table: 32,
        from: 77201,
        to: 77400,
        value: 29135,
        retirement: 29030,
    },
    {
        table: 32,
        from: 77401,
        to: 77600,
        value: 29245,
        retirement: 29139,
    },
    {
        table: 32,
        from: 77601,
        to: 77800,
        value: 29355,
        retirement: 29251,
    },
    {
        table: 32,
        from: 77801,
        to: 78000,
        value: 29465,
        retirement: 29360,
    },
    {
        table: 32,
        from: 78001,
        to: 78200,
        value: 29575,
        retirement: 29468,
    },
    {
        table: 32,
        from: 78201,
        to: 78400,
        value: 29685,
        retirement: 29581,
    },
    {
        table: 32,
        from: 78401,
        to: 78600,
        value: 29795,
        retirement: 29689,
    },
    {
        table: 32,
        from: 78601,
        to: 78800,
        value: 29905,
        retirement: 29802,
    },
    {
        table: 32,
        from: 78801,
        to: 79000,
        value: 30015,
        retirement: 29910,
    },
    {
        table: 32,
        from: 79001,
        to: 79200,
        value: 30125,
        retirement: 30018,
    },
    {
        table: 32,
        from: 79201,
        to: 79400,
        value: 30235,
        retirement: 30131,
    },
    {
        table: 32,
        from: 79401,
        to: 79600,
        value: 30345,
        retirement: 30239,
    },
    {
        table: 32,
        from: 79601,
        to: 79800,
        value: 30455,
        retirement: 30348,
    },
    {
        table: 32,
        from: 79801,
        to: 80000,
        value: 30565,
        retirement: 30460,
    },
    {
        table: 32,
        from: 80001,
        to: 80200,
        value: 38,
        retirement: 38,
    },
    {
        table: 32,
        from: 80201,
        to: 81200,
        value: 38,
        retirement: 38,
    },
    {
        table: 32,
        from: 81201,
        to: 81400,
        value: 38,
        retirement: 38,
    },
    {
        table: 32,
        from: 81401,
        to: 82000,
        value: 39,
        retirement: 38,
    },
    {
        table: 32,
        from: 82001,
        to: 85400,
        value: 39,
        retirement: 39,
    },
    {
        table: 32,
        from: 85401,
        to: 86600,
        value: 39,
        retirement: 39,
    },
    {
        table: 32,
        from: 86601,
        to: 87200,
        value: 40,
        retirement: 39,
    },
    {
        table: 32,
        from: 87201,
        to: 87600,
        value: 40,
        retirement: 40,
    },
    {
        table: 32,
        from: 87601,
        to: 88800,
        value: 40,
        retirement: 40,
    },
    {
        table: 32,
        from: 88801,
        to: 89800,
        value: 40,
        retirement: 40,
    },
    {
        table: 32,
        from: 89801,
        to: 92600,
        value: 40,
        retirement: 40,
    },
    {
        table: 32,
        from: 92601,
        to: 93200,
        value: 41,
        retirement: 40,
    },
    {
        table: 32,
        from: 93201,
        to: 94800,
        value: 41,
        retirement: 41,
    },
    {
        table: 32,
        from: 94801,
        to: 96600,
        value: 41,
        retirement: 41,
    },
    {
        table: 32,
        from: 96601,
        to: 98200,
        value: 41,
        retirement: 41,
    },
    {
        table: 32,
        from: 98201,
        to: 99400,
        value: 41,
        retirement: 41,
    },
    {
        table: 32,
        from: 99401,
        to: 100200,
        value: 42,
        retirement: 41,
    },
    {
        table: 32,
        from: 100201,
        to: 106200,
        value: 42,
        retirement: 42,
    },
    {
        table: 32,
        from: 106201,
        to: 107400,
        value: 42,
        retirement: 42,
    },
    {
        table: 32,
        from: 107401,
        to: 107600,
        value: 43,
        retirement: 42,
    },
    {
        table: 32,
        from: 107601,
        to: 108200,
        value: 43,
        retirement: 42,
    },
    {
        table: 32,
        from: 108201,
        to: 109800,
        value: 43,
        retirement: 43,
    },
    {
        table: 32,
        from: 109801,
        to: 113200,
        value: 43,
        retirement: 43,
    },
    {
        table: 32,
        from: 113201,
        to: 116800,
        value: 43,
        retirement: 43,
    },
    {
        table: 32,
        from: 116801,
        to: 117600,
        value: 44,
        retirement: 43,
    },
    {
        table: 32,
        from: 117601,
        to: 121000,
        value: 44,
        retirement: 44,
    },
    {
        table: 32,
        from: 121001,
        to: 121600,
        value: 44,
        retirement: 44,
    },
    {
        table: 32,
        from: 121601,
        to: 124400,
        value: 44,
        retirement: 44,
    },
    {
        table: 32,
        from: 124401,
        to: 127800,
        value: 44,
        retirement: 44,
    },
    {
        table: 32,
        from: 127801,
        to: 129000,
        value: 45,
        retirement: 44,
    },
    {
        table: 32,
        from: 129001,
        to: 129800,
        value: 45,
        retirement: 45,
    },
    {
        table: 32,
        from: 129801,
        to: 139600,
        value: 45,
        retirement: 45,
    },
    {
        table: 32,
        from: 139601,
        to: 143600,
        value: 45,
        retirement: 45,
    },
    {
        table: 32,
        from: 143601,
        to: 164000,
        value: 46,
        retirement: 46,
    },
    {
        table: 32,
        from: 164001,
        to: 169800,
        value: 46,
        retirement: 46,
    },
    {
        table: 32,
        from: 169801,
        to: 198800,
        value: 47,
        retirement: 47,
    },
    {
        table: 32,
        from: 198801,
        to: 207400,
        value: 47,
        retirement: 47,
    },
    {
        table: 32,
        from: 207401,
        to: 252000,
        value: 48,
        retirement: 48,
    },
    {
        table: 32,
        from: 252001,
        to: 266800,
        value: 48,
        retirement: 48,
    },
    {
        table: 32,
        from: 266801,
        to: 344400,
        value: 49,
        retirement: 49,
    },
    {
        table: 32,
        from: 344401,
        to: 373600,
        value: 49,
        retirement: 49,
    },
    {
        table: 32,
        from: 373601,
        to: 543800,
        value: 50,
        retirement: 50,
    },
    {
        table: 32,
        from: 543801,
        to: 622600,
        value: 50,
        retirement: 50,
    },
    {
        table: 32,
        from: 622601,
        to: 1289800,
        value: 51,
        retirement: 51,
    },
    {
        table: 32,
        from: 1289801,
        to: 1868000,
        value: 51,
        retirement: 51,
    },
    {
        table: 32,
        from: 1868001,
        to: Infinity,
        value: 52,
        retirement: 52,
    },
];
